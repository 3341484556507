import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    maxWidth: 367,
    width: "100%",
    backgroundColor: "#F4F6FA",
    borderRadius: 12,
    padding: 20,
    marginTop: 25,
  },
  userContainer: { marginTop: 10, paddingVertical: 20 },
  userTextContainer: { marginTop: 5 },
  image: { height: 44, width: 44, borderRadius: 50 },
});
