import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  leftContainer: {
    flex: 0.3,
    maxWidth: 260,
    borderRightWidth: 1,
    borderColor: "#A1A1A1",
  },
  leftBody: { padding: 25, flex: 1 },
  leftText: {},
  modalContent: {
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    flexDirection: "row",
    maxWidth: 1200,
    maxHeight: 646,
    width: "90%",
    height: "90%",
    borderRadius: 8,
    elevation: 5,
  },
  rightContainer: {
    flex: 0.8,
    paddingTop: 12,
    paddingLeft: 40,
  },
  bodyContainer: { paddingVertical: 12 },
  buttons: { paddingBottom: 25, width: "100%" },
  button: {
    alignItems: "baseline",
    justifyContent: "flex-start",
    marginBottom: 2,
    width: "100%",
    flex: 1,
  },
  text: {
    fontFamily: "LindseyRegular",
    fontWeight: "bold",
    fontSize: 32,
  },
  tutorialContainer: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 5,
    marginTop: 20,
  },
});
