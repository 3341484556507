import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const styles = StyleSheet.create({
  itemContainer: {
    borderWidth: 2,
    borderColor: COLORS.BORDER,
    borderRadius: 6,
    marginBottom: 5,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 16,
    width: "80%",
    maxWidth: 500,
  },
  input: {
    borderWidth: 1,
    borderColor: "gray",
    padding: 8,
    marginLeft: 5,
    borderRadius: 8,
  },
  buttonsContainer: {
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
  },
});
