import { Dimensions, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import globalStyles from "../../constants/globalStyles";

const Privacy = () => {
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View
      style={[
        styles.container,
        { paddingHorizontal: screenWidth > 800 ? 44 : 20 },
      ]}
    >
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Privacy Policy"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Last updated: January 24, 2023"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "LearnBase is committed to protecting the privacy of all its users. \n In order to ensure that our customers and users are fully informed of our data handling policies and their rights pertaining to this data, we maintain this page which details our practices around information collected through the LearnBase platform."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Overview"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "Our approach to data security and privacy includes but is not limited to: \n - State-of-the-art platform security \n - At-rest and in-transit encryption of all customer and end-user data \n - Providing customers with the tools to control the amount of personally-identifiable information (PII) handled by LearnBase \n"
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Kinds of Information We Collect"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "LearnBase processes four different categories of data, which reflect the different levels of sensitivity in context. However, there are several common traits about how we handle the data, regardless of type: \n"
          }
          {
            " - We never sell this data to third parties. In limited cases, we provide it to third-party services for usage strictly within the LearnBase product or business; for example, to provide analytics graphs on the LearnBase dashboard. \n"
          }
          {
            " - All data is subject to the protections of the General Data Protection Regulation, in the case it originates from the European Union."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "bold",
          }}
        >
          {"Type 1: End-user PII"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "‍This data can be used to identify a specific user. Examples of end-user PII include: \n - User profile data passed to LearnBase by the customer, using the `LearnBase.identify()` SDK function; \n - Browser information that is collected by default in the LearnBase SDK (e.g., OS, device type, browser language, user agent), when associated with a particular user; and \n - Browsing history data that is collected by default in the LearnBase SDK (e.g., current page URL, current page title). \n\n Again, like all data we collect, **we never sell end-user PII to third parties.**\n Customers may opt out of browser and browser history information by contacting [LearnBase Support](mailto:info@learnbase.co).\n We use this data to customize and deliver LearnBase content."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "bold",
          }}
        >
          {"Type 2: End-user LearnBase Data"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "This data pertains to how end users are interacting with LearnBase content; for example, whether a flow was shown to a given user, whether a user has interacted with a tooltip, etc. This category also includes user responses to in-LearnBase forms or surveys. \n We do not actively collect PII for use in this category, and no PII is required in this category in order to use LearnBase. **Note, however, that form or survey responses may add PII to this data**. \n We use data in this category to customize and deliver LearnBase content, as well as display analytics on the LearnBase dashboard. \n It is this data stream which is available for CSV download on the LearnBase dashboard.\n Customers may also configure LearnBase to send certain data upstream to other services."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "bold",
          }}
        >
          {"Type 3: Customer PII"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "‍We collect customer PII through the LearnBase dashboard. This category of data includes business-relationship information, such as the name and email address of each of a customer's team members who are authorized to use the LearnBase platform. \n LearnBase does not handle or store financial data about customers (e.g., credit card information). Instead, we use a fully PCI DSS compliant payments processor.\n We use this type of data mainly in the LearnBase dashboard and editor, and within the LearnBase business."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "bold",
          }}
        >
          {"Type 4: Customer Aggregate Data"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "This category includes customer-wide, aggregated statistics such as active user count, number of LearnBase flows shown, how many LearnBase flows are published at a time, etc. \n This data does not contain PII.\n We use data in this category mainly in the LearnBase dashboard and editor, customer emails, and within the LearnBase business."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Security and Compliance"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "bold",
          }}
        >
          {"Encryption"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "LearnBase is committed to the privacy of information as it passes over our network, as well as to preventing unauthorized access to customer or end-user data. Among other technical and organizational measures we have implemented to protect data, we use industry-leading encryption to protect all external traffic in transit (via HTTPS/TLS) and at rest (using AES-256 and an automated key rotation system)."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "bold",
          }}
        >
          {"Data Retention"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "We delete end-user and customer data promptly upon verified request by the applicable customer or end user, except to the extent required by applicable law or to perform or enforce the terms of applicable contracts. \n\n Requests for data deletion may be addressed to [info@learnbase.co](mailto:info@learnbase.co)."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "bold",
          }}
        >
          {
            "European Union-United States and Swiss-United States Privacy Shield"
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          We are aware of the July 16, 2020 decision of the European Court of
          Justice relating to the EU-US Privacy Shield and the September 8, 2020
          opinion of the Federal Data Protection and Information Commissioner of
          Switzerland (FDPIC) relating to the Swiss-US Privacy Shield, in each
          case causing the applicable Privacy Shield Framework to be unavailable
          as a valid mechanism for data transfers to the United States.
          Nevertheless, in accordance with guidance from the United States
          Department of Commerce, LearnBase continues to be responsible for its
          obligations under both frameworks. Accordingly, we remain committed to
          the Privacy Shield Frameworks as set forth below, pending the outcome
          of negotiations between the Commerce Department and EU and Swiss
          authorities for an enhanced Privacy Shield program. Meantime, for
          concerned customers, LearnBase will be happy to enter into an
          appropriate data processing addendum, including the “standard
          contractual clauses” approved by the European Commission and
          recognized by the FDPIC. \n\n LearnBase complies with the EU-US and
          Swiss-US Privacy Shield Frameworks and associated Privacy Shield
          Principles, as set forth by the United States Department of Commerce
          regarding the collection, use, and retention of personal information
          from European Union member countries, the United Kingdom and
          Switzerland. We have certified that we adhere to the Privacy Shield
          principles of Notice, Choice, Accountability for Onward Transfer,
          Security, Data Integrity and Purpose Limitation, Access, and Recourse,
          Enforcement, and Liability. If there is any conflict between this
          privacy policy and the Privacy Shield Principles, the Privacy Shield
          Principles shall govern. To learn more about the Privacy Shield
          program, and to view our certification page, please visit
          [https://www.privacyshield.gov/](https://www.privacyshield.gov/). \nn
          compliance with the EU-US and Swiss-US Privacy Shield Principles, we
          commit to resolve complaints about your privacy and our collection or
          use of your personal information. European Union, United Kingdom and
          Swiss individuals with inquiries or complaints regarding this privacy
          policy should first contact us using the contact information specified
          in this privacy policy. \n In compliance with the Privacy Shield
          Principles, LearnBase commits to resolve complaints about our
          collection or use of your personal information. EU and Swiss
          individuals with inquiries or complaints regarding our Privacy Shield
          policy should first contact *LearnBase* at: \n
          [info@learnbase.co](mailto:support@learnbase.co) \n LearnBase has
          further committed to cooperate with the panel established by the EU
          data protection authorities (DPAs) and the Swiss Federal Data
          Protection and Information Commissioner (FDPIC) with regard to
          unresolved Privacy Shield complaints concerning data transferred from
          the EU and Switzerland. \n As a last resort and under certain limited
          and prescribed circumstances and conditions, you have the right to
          invoke a “last resort” binding arbitration process between you and us
          to resolve a dispute related to our collection, use or disclosure of
          your personal information.\n Without limiting our other obligations to
          you set forth in this privacy policy, we will maintain compliance with
          the Privacy Shield principles by adhering to the following practices:
          \n *Notice*\n When we collect your personal information, we will give
          you timely and appropriate notice describing what personal information
          we are collecting, how we will use it, and the types of third parties
          with whom we may share it.  This privacy policy serves as such notice,
          and any changes to our collection, use or disclosure of your personal
          information will be reflected in revisions to the privacy policy
          posted on our website. \n\n *Choice*\n As established and described in
          this privacy policy and enabled through our platform, we will give you
          choices about the ways we use and share your personal information, and
          we will respect the choices you make, including choosing to change the
          way in which we use, store, process, or share your personal
          information.\n *Accountability for Onward Transfer*\n If we transfer
          your personal information to another country, we may remain liable and
          will take appropriate measures to protect your privacy and the
          personal information we transfer.\n *Security*\n We will take
          appropriate physical, technical, and organizational measures to
          protect your personal information from loss, misuse, unauthorized
          access or disclosure, alteration, and destruction, as further
          specified in this privacy policy.\n *Data Integrity and Purpose
          Limitation*\n We will collect only as much personal information as we
          need for specific, identified purposes, and we will not use it for
          other purposes without obtaining your consent. We will take
          appropriate steps to make sure the personal information in our records
          is accurate.\n *Access*\n If you wish to confirm the accuracy of your
          personal information or have it removed from our systems and records,
          you may contact us at the email address, telephone number or postal
          address provided in this privacy policy. \n\n *Recourse, Enforcement,
          and Liability*\n We will regularly review our continued adherence to
          our privacy obligations, and we will provide and maintain the
          independent mechanism specified in this privacy policy as a way of
          resolving complaints or concerns about our privacy practices.
           Further, we acknowledge our potential liability for misuse of your
          personal information by us or our third-party service providers.
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "bold",
          }}
        >
          {"Other Applicable Law"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "You may have other rights under applicable data protection laws. For example, if you are a resident of California, then under California law, including the California Consumer Privacy Act (“CCPA”), you may have specific rights relating to your PII. Your rights depend on the nature and purpose of the collection and use of your PII and may include the right to be informed about categories of PII we collect, categories of the sources of PII, and categories of third parties with whom we share PII.  This information is provided in the applicable sections of this privacy policy. You may also have the right to request information about the specific PII we may have about you, and you may do so by contacting us as set forth below.  In some cases, you may have the right to request that we delete PII we may have about you. We will respond and, where applicable, comply with your requests free of charge and within the timeframe required under applicable law. \n"
          }
          {
            "As noted above, we do not sell PII for direct marketing or any other purpose. Therefore, the elements of CCPA or other applicable law relating to the sale or “commercial” use of PII do not apply to us. Similarly, we do not track users over time across third-party, non-customer websites, and therefore we do not recognize or respond to browser-initiated “do not track” signals. You do not need to establish an account with us or be a registered user in order to send us a request, but if you already have an account with us, we may communicate with you about your request through your account. We do not discriminate against our users based on their data-privacy choices or the exercise of their rights under applicable data protection laws. \n"
          }
          {
            "We respect the privacy rights of all of our users. We are committed to complying with data protection laws to the extent they apply to us, and to assist our customers in their compliance obligations as applicable and appropriate. To exercise your rights, please contact us at the address listed below. Please allow us a reasonable time to respond to your request."
          }
          {
            "Please note that your rights under certain data protection laws depend in part on the nature of your relationship with us.  For example, if we are processing your PII in the role of a service provider to your organization as our customer, then your organization is responsible for the instructions it gives to us regarding your PII, and if you wish to exercise any rights you may have under applicable data protection laws, please direct your inquiry to your organization. Because we may only access and use our customer’s data (which may include your PII) in accordance with instructions from the applicable customer, if you are a customer user and you make your request directly to us, we will refer your request to that customer, although we will support them as required by applicable data protection laws in responding to your request."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Other Uses of Data"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "bold",
          }}
        >
          {"Enforcement"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "All policies and practices described in this privacy policy are subject to our obligation to  comply with applicable law, including any lawful request by public authorities. We may disclose any information necessary in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of service or other applicable agreement, or as otherwise required by law."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "bold",
          }}
        >
          {"Transfer of Ownership"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "If LearnBase merges with or is acquired by another company, our date, including your personal information, may be transferred to the other company, and the terms of this privacy policy may be subject to change."
          }
          {
            "We use the information you provide about yourself when doing business with us only to provide the service that you have requested, including customer service, during the term of your or your organization’s agreement with us. We do not share this information with outside parties, other than the service providers described above, without your permission."
          }
          {
            "Finally, we never use or share PII provided to us online in ways unrelated to the ones described above without also providing you an opportunity to opt-out or otherwise prohibit such unrelated uses, except under the circumstances described in this section."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Changes to this Privacy Policy"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "We reserve the right to change this privacy policy at any time. Any changes we make will be effective immediately as of the date the modified privacy policy is made available through our services or on our website. By continuing to access or use our services after we have posted a modification to this privacy policy, you are indicating that you agree to the terms of the modified privacy policy."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Contact Information"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "If you have any questions about this privacy policy, our collection and use of your personal information, or to exercise your rights under this privacy policy and applicable law, please contact us at info@learnbase.co"
          }
        </Text>
      </View>
    </View>
  );
};

export default Privacy;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEF1F5",
    paddingVertical: 70,
  },
  featureContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    marginTop: 50,
    width: "100%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  heroMobileContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  leftContainer: {
    //maxWidth: 600,
    marginBottom: 20,
  },
  rightContainer: {
    justifyContent: "flex-start",
  },
  image: {
    height: 500,
    width: 500,
    resizeMode: "contain",
  },
});
