import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 10,
    width: "100%",
  },
  titleContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    flex: 1,
    alignSelf: "center",
    position: "absolute",
    fontSize: 18,
    fontWeight: "bold",
    color: COLORS.PRIMARY,
  },
  footerContainer: {
    flexDirection: "row",
    width: "100%",
    height: 84,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bodyContainer: { flex: 1, flexDirection: "row" },
  leftContainer: {
    height: "100%",
    width: "40%",
    alignItems: "baseline",
    justifyContent: "center",
  },
  rightContainer: {
    padding: 12,
    flex: 1,
  },
  button: { width: 114, height: 36, marginTop: 24 },
  addStepBody: { flex: 1, flexDirection: "column", padding: 20 },
  input: {
    height: 56,
    backgroundColor: COLORS.TEXT_LIGHT,
    maxWidth: 350,
    width: "100%",
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: COLORS.BORDER,
    padding: 20,
    color: COLORS.TEXT_DARK,
    marginBottom: 12,
  },
});
