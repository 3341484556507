import React, { useRef, useState } from "react";
import { View, Dimensions, ScrollView } from "react-native";
import getUniqueID from "../../utils/generateId";
import { styles } from "./styles";
import Header from "../../components/Header";
import { Footer } from "../../components/Footer";
import Pricing from "../../components/Sections/Pricing";
import { useTranslation } from "react-i18next";
import SignInForm from "../../components/Auth/SignInForm";

const SignInScreen = ({ route }) => {
  const { t } = useTranslation();
  return (
    <View style={styles.main}>
      <SignInForm selectedPrice={route.params.item} />
    </View>
  );
};

export default SignInScreen;
