import React from "react";
import { View } from "react-native";
import { styles } from "./styles";
import Header from "../../components/Header";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";
import Contact from "../../components/Footer/Contact";

const ContactScreen = () => {
  const { t } = useTranslation();
  return (
    <View style={styles.main}>
      <Header />

      <Contact />

      <Footer />
    </View>
  );
};

export default ContactScreen;
