import {
  Button,
  Dimensions,
  Image,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useState } from "react";
import { CustomButton, MainButton } from "../Button";
import { COLORS, globalStyles } from "../../constants";
import { Video, AVPlaybackStatus } from "expo-av";

const ViewArea = ({ onClose, data }) => {
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("window").width
  );
  const [screenHeight, setScreenHeight] = useState(
    Dimensions.get("window").height
  );
  Dimensions.addEventListener("change", ({ window }) => {
    setScreenHeight(window.height);
  });
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <View style={styles.titleContainer}>
          <Text
            style={[globalStyles.headingMediumBold, { color: COLORS.PRIMARY }]}
          >
            {data.title}
          </Text>
          <Text style={[globalStyles.bodyMediumSemiBold, { width: "100%" }]}>
            {data.description}
          </Text>
        </View>

        {/* <MainButton onPress={onClose} leftIcon={"close"} type={"secondary"} /> */}
        <CustomButton
          onPress={onClose}
          leftIcon={"close"}
          rounded
          containerStyle={{
            height: 32,
            width: 32,
            backgroundColor: COLORS.GREY_SOFT,
          }}
          iconColor={COLORS.BLACK_SOFT}
        />
      </View>
      <Image
        style={[
          styles.image,
          {
            height:
              screenHeight > 520
                ? 270
                : screenHeight > 460
                ? 220
                : screenHeight > 410
                ? 180
                : 150,
            //width: screenHeight > 520 ? 330 : screenHeight > 460 ? 270 : 220,
          },
        ]}
        source={data.url}
      />
    </View>
  );
};
export const VideoViewArea = ({ onClose, data }) => {
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <View style={styles.titleContainer}>
          <Text
            style={[globalStyles.headingMediumBold, { color: COLORS.PRIMARY }]}
          >
            {data.title}
          </Text>
          <Text style={[globalStyles.bodyMediumSemiBold, { width: "100%" }]}>
            {data.description}
          </Text>
        </View>

        {/* <MainButton onPress={onClose} leftIcon={"close"} type={"secondary"} /> */}
        <CustomButton
          onPress={onClose}
          leftIcon={"close"}
          rounded
          containerStyle={{
            height: 32,
            width: 32,
            backgroundColor: COLORS.GREY_SOFT,
          }}
          iconColor={COLORS.BLACK_SOFT}
        />
      </View>
      <View style={{ flex: 1 }}>
        <Video
          ref={video}
          source={data.imageUrl}
          style={{ flex: 1 }}
          videoStyle={{
            width: "100%",
            resizeMode: "contain",
            alignSelf: "center",
            justifyContent: "center",
            borderRadius: 8,
          }}
          useNativeControls
          resizeMode="contain"
          onPlaybackStatusUpdate={(status) => setStatus(() => status)}
        />
      </View>
    </View>
  );
};

export default ViewArea;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignSelf: "center",
    width: "100%",
    marginBottom: 25,
  },
  titleContainer: {
    justifyContent: "flex-start",
    alignItems: "baseline",
    width: "90%",
  },
  title: {
    alignSelf: "center",
    fontSize: 18,
    fontWeight: "bold",
    color: COLORS.PRIMARY,
  },
  image: {
    height: 270,
    width: 330,
    resizeMode: "cover",
    alignSelf: "center",
    justifyContent: "center",
    borderRadius: 8,
  },
  video: {
    flex: 1,
  },
});
