import { View } from "react-native";
import React, { useState } from "react";
import { styles } from "./styles";
import { COLORS } from "../../constants/colors";
import ViewArea, { VideoViewArea } from "./ViewArea";
import { CustomButton } from "../Button";
import CarouselPagination from "../Carousel/carouselPagination";

const TutorialModal = ({ visible, setVisible, data }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const handleSave = () => {
    setActiveStepIndex(activeStepIndex + 1);
  };

  const onClose = () => {
    setVisible(false);
    setActiveStepIndex(0);
  };

  const activeStep =
    data?.tutorialSteps.length > 0 && data?.tutorialSteps[activeStepIndex];
  return (
    visible && (
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          {activeStep && <ViewArea onClose={onClose} data={activeStep} />}
          {!activeStep && <VideoViewArea onClose={onClose} data={data} />}
          <View style={styles.footerContainer}>
            <CarouselPagination
              count={data?.tutorialSteps.length}
              active={activeStepIndex}
            />
            <View style={styles.footerButtonContainer}>
              {activeStepIndex > 0 && (
                <CustomButton
                  title="Back"
                  onPress={() => setActiveStepIndex(activeStepIndex - 1)}
                  styleType={"tertiary"}
                  rounded
                  textStyle={{ color: COLORS.TEXT_DARK }}
                />
              )}
              <View style={{ width: 10 }}></View>
              {activeStepIndex < data?.tutorialSteps.length - 1 ? (
                <CustomButton
                  title="Next"
                  onPress={handleSave}
                  rounded
                  containerStyle={{ height: 48, minWidth: 69 }}
                />
              ) : (
                <CustomButton
                  title="Finish"
                  onPress={onClose}
                  rounded
                  containerStyle={{ height: 48, minWidth: 69 }}
                />
              )}
            </View>
          </View>
        </View>
      </View>
    )
  );
};

export default TutorialModal;
