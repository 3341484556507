import React from "react";
import { View, Text } from "react-native";
import LearningCenter from "../../components/LearningCenter";
import { styles } from "./styles";

const DemoScreen = () => {
  return (
    <View style={styles.body}>
      <View style={styles.panelContent}>
        <View style={styles.sidePanel}>{/* Side Panel */}</View>
        <View style={styles.contentContainer}>
          <View style={styles.upperPanel}>{/* Top content */}</View>
        </View>
      </View>
      <LearningCenter />
    </View>
  );
};

export default DemoScreen;
