import React, { useRef } from "react";
import { View } from "react-native";
import { styles } from "./styles";
import Header from "../../components/Header";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";
import FAQ from "../../components/Footer/FAQ";
import How from "../../components/Footer/How";

const HowScreen = () => {
  return (
    <View style={styles.main}>
      <Header />

      <How />

      <Footer />
    </View>
  );
};

export default HowScreen;
