import React, { useState } from "react";
import { View, Modal, Text, ScrollView } from "react-native";
import { COLORS, globalStyles } from "../../constants";
import { CustomButton, ImageButton, MainButton } from "../Button";
import CardsList from "../CardList";
import { ModalHeader } from "../Modal";
import { styles } from "./styles";

const Demo = ({
  toggleTutorialModal,
  setData,
  modalVisible,
  setModalVisible,
}) => {
  const [selected, setSelected] = useState("Tutorial");
  const handlePress = () => {
    setModalVisible(true);
  };
  const handleSave = (section) => {
    null;
  };
  const handleSelected = (item) => {
    setSelected(item);
  };
  const onClose = () => {
    setModalVisible(false);
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTutorial = (item) => {
    if (item === selected) {
      handleSelected("Tutorial");
      setShowTooltip(false);
    }
    if (item !== selected) {
      handleSelected(item);
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 3000); // set timer for 3 seconds
    }
  };

  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (query) => {
    // Perform search and update searchResults state
  };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        position: "absolute",
        right: 25,
        bottom: 25,
      }}
    >
      <Text style={styles.text}>{"Try me ->"}</Text>
      <CustomButton
        leftIcon={"md-help-circle"}
        iconSize={64}
        iconColor={"#303030"}
        onPress={handlePress}
        containerStyle={{ backgroundColor: "transparent" }}
      />
      <Modal
        visible={modalVisible}
        animationType="fade"
        transparent={true}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <View style={styles.leftContainer}>
              <ScrollView style={styles.leftBody}>
                <Text
                  style={[globalStyles.headingMedium, { paddingBottom: 15 }]}
                >
                  Learning Center
                </Text>
                <View style={styles.buttons}>
                  <MainButton
                    title={"Tutorial"}
                    leftIcon={"flag-outline"}
                    onPress={() => handleSelected("Tutorial")}
                    selected={"Tutorial"}
                  />
                  {/* <View style={{ height: 2 }} />
                  <MainButton
                    title={"Onboarding"}
                    leftIcon={"albums-outline"}
                    onPress={() => handleSelected("Onboarding")}
                    selected={selected}
                  /> */}
                </View>
                <View style={styles.buttons}>
                  <Text
                    style={[
                      globalStyles.inputSmallRegular,
                      { color: COLORS.GREY_SOFT },
                    ]}
                  >
                    Links
                  </Text>
                  <MainButton
                    title={"Help Center"}
                    leftIcon={"book-outline"}
                    onPress={() => toggleTutorial("Help Center")}
                    showTooltip={showTooltip && selected === "Help Center"}
                    rightIcon={"open-outline"}
                  />
                  <View style={{ height: 2 }} />
                  <MainButton
                    title={"FAQ"}
                    leftIcon={"school-outline"}
                    onPress={() => toggleTutorial("FAQ")}
                    showTooltip={showTooltip && selected === "FAQ"}
                    rightIcon={"open-outline"}
                  />
                  <View style={{ height: 2 }} />
                  <MainButton
                    leftIcon={"chatbubbles-outline"}
                    title={"Contact Support"}
                    onPress={() => toggleTutorial("Contact Support")}
                    showTooltip={showTooltip && selected === "Contact Support"}
                    rightIcon={"open-outline"}
                  />
                  <View style={{ height: 2 }} />
                  <MainButton
                    leftIcon={"mail-open-outline"}
                    title={"Submit Feedback"}
                    onPress={() => toggleTutorial("Submit Feedback")}
                    showTooltip={showTooltip && selected === "Submit Feedback"}
                    rightIcon={"open-outline"}
                  />
                  <View style={{ height: 2 }} />

                  <MainButton
                    leftIcon={"gift-outline"}
                    title={"What's new"}
                    onPress={() => toggleTutorial("What's new")}
                    showTooltip={showTooltip && selected === "What's new"}
                    rightIcon={"open-outline"}
                  />
                </View>
                <View style={styles.buttons}>
                  <Text
                    style={[
                      globalStyles.inputSmallRegular,
                      { color: COLORS.GREY_SOFT },
                    ]}
                  >
                    Social Links
                  </Text>
                  <ImageButton
                    source={require("../../../assets/images/logos/facebook.png")}
                    title={"Facebook"}
                    onPress={() => toggleTutorial("Facebook")}
                    showTooltip={showTooltip && selected === "Facebook"}
                  />
                  <View style={{ height: 2 }} />
                  <ImageButton
                    source={require("../../../assets/images/logos/twitter.png")}
                    title={"Twitter"}
                    onPress={() => toggleTutorial("Twitter")}
                    showTooltip={showTooltip && selected === "Twitter"}
                  />
                  <View style={{ height: 2 }} />
                  <ImageButton
                    source={require("../../../assets/images/logos/linkedin.png")}
                    title={"Linkedin"}
                    onPress={() => toggleTutorial("Linkedin")}
                    showTooltip={showTooltip && selected === "Linkedin"}
                  />
                  <View style={{ height: 2 }} />
                  <ImageButton
                    source={require("../../../assets/images/logos/youtube.png")}
                    title={"YouTube"}
                    onPress={() => toggleTutorial("YouTube")}
                    showTooltip={showTooltip && selected === "YouTube"}
                  />
                </View>
              </ScrollView>
              <View
                style={{
                  height: 33,
                  width: "100%",
                  backgroundColor: "#F8F8F8",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottomLeftRadius: 8,
                }}
              >
                <Text style={globalStyles.inputMediumRegular}>
                  Supported by{" "}
                  <Text style={globalStyles.inputMediumSemiBold}>
                    LearnBase
                  </Text>
                </Text>
              </View>
            </View>
            <View style={styles.rightContainer}>
              <ModalHeader onClose={onClose} onSearch={handleSearch} />
              <ScrollView style={styles.bodyContainer}>
                <Text style={[globalStyles.headingLarge, { marginBottom: 5 }]}>
                  Hey there,
                </Text>
                <Text style={globalStyles.bodyMediumRegular}>
                  This is how your website learning center would look like. Add
                  step-by-step tutorials or videos here.
                </Text>
                <Text style={globalStyles.bodyMediumRegular}>
                  Customize side panel and add important urls for your website.
                </Text>
                <CardsList onPress={toggleTutorialModal} setItem={setData} />
              </ScrollView>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default Demo;
