import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "./styles";
import globalStyles from "../../constants/globalStyles";
import { COLORS } from "../../constants/colors";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { LinearGradient } from "expo-linear-gradient";
import Tooltip from "../Tooltip";

export const IconButton = ({
  icon = "person",
  color,
  backgroundColor,
  borderRadius,
  size = 24,
  onPress,
  disabled = false,
}) => {
  return (
    <Pressable
      disabled={disabled}
      hitSlop={8}
      style={({ pressed }) => [
        {
          opacity: pressed || disabled ? 0.5 : 1.0,
          backgroundColor: backgroundColor,
          borderRadius: borderRadius,
          padding: 5,
        },
      ]}
      onPress={onPress}
    >
      <Icon name={icon} size={size} color={color} />
    </Pressable>
  );
};
export const AuthButtons = ({ loginPressed, signUpPressed, horizontal }) => {
  const { t } = useTranslation();

  return (
    <View
      style={{
        flexDirection: horizontal ? "column" : "row",
        alignSelf: "center",
        alignItems: "center",
      }}
    >
      {/* <ActionButtonInverse title={t("header.signIn")} onPress={loginPressed} />
      {horizontal && <View style={{ height: 20 }}></View>} */}
      <ActionButton title={t("header.startFree")} onPress={signUpPressed} />
    </View>
  );
};
export const HeroAction = ({
  textColor = COLORS.PRIMARY,
  title = "..",
  onPress,
  style,
  disabled = false,
  source,
  imageStyle,
}) => {
  return (
    <TouchableOpacity
      style={[globalStyles.actionButton, style]}
      onPress={async () => {
        onPress();
      }}
      disabled={disabled}
    >
      {source && <Image source={source} style={imageStyle} />}
      <Text style={{ ...globalStyles.actionButtonText, color: textColor }}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};
export const ActionButton = ({
  backgroundColor = COLORS.PRIMARY,
  textColor = COLORS.WHITE,
  title = "..",
  onPress,
}) => {
  return (
    <TouchableOpacity
      style={{ ...globalStyles.actionButton, backgroundColor: backgroundColor }}
      onPress={async () => {
        onPress();
        /* await analytics.logEvent(`${title.replace(/[^\w]/gi, "")}`, {
          name: { title },
        });
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light); */
      }}
    >
      <Text style={{ ...globalStyles.actionButtonText, color: textColor }}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export const ActionButtonInverse = ({
  backgroundColor = "transparent",
  textColor = COLORS.PRIMARY,
  title = "Login",
  onPress,
}) => {
  return (
    <TouchableOpacity
      style={{
        ...globalStyles.actionButton,
        backgroundColor: backgroundColor,
        borderWidth: 1,
        borderColor: COLORS.PRIMARY,
        flexDirection: "row",
        alignItems: "center",
      }}
      onPress={async () => {
        onPress();
        /*  await analytics.logEvent(`${title.replace(/[^\w]/gi, "")}`, {
          name: { title },
        });
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light); */
      }}
    >
      <Icon
        name={"person-outline"}
        size={24}
        color={COLORS.PRIMARY}
        style={{ alignSelf: "center", marginRight: 3 }}
      />
      <Text style={{ ...globalStyles.actionButtonText, color: textColor }}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};
export const TextButton = ({ text = "..", onPress, textStyle }) => {
  return (
    <Pressable
      style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1.0 }]}
      onPress={async () => {
        onPress();
        /*  await analytics.logEvent(`${text.replace(/[^\w]/gi, "")}`, {
          name: { text },
        });
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light); */
      }}
    >
      <View>
        <Text
          style={[globalStyles.textButton, { color: "#6E6E6E" }, textStyle]}
        >
          {text}
        </Text>
      </View>
    </Pressable>
  );
};
export const CustomButton = ({
  title,
  onPress,
  disabled,
  loading,
  accessibilityLabel,
  testID,
  styleType = "primary",
  rounded,
  outline,
  borderColor,
  borderWidth,
  error,
  hoverColor,
  activeOpacity,
  disabledStyle,
  icon,
  leftIcon,
  rightIcon,
  iconSize = 20,
  iconColor = "#fff",
  iconPosition = "left",
  textStyle,
  containerStyle,
}) => {
  let backgroundColor;
  let textColor;
  let borderColorStyle;
  let errorColor;

  if (styleType === "primary") {
    backgroundColor = disabled ? "#ccc" : "#3E4BBC";
    textColor = disabled ? "#999" : "#fff";
    borderColorStyle = outline ? borderColor || backgroundColor : "#fff";
    errorColor = error ? "#dc3545" : backgroundColor;
  } else if (styleType === "secondary") {
    backgroundColor = disabled ? "#f8f9fa" : "#6c757d";
    textColor = disabled ? "#999" : "#fff";
    borderColorStyle = outline ? borderColor || backgroundColor : "#fff";
    errorColor = error ? "#dc3545" : backgroundColor;
  } else if (styleType === "tertiary") {
    backgroundColor = disabled ? "#f8f9fa" : "transparent";
    textColor = disabled ? "#999" : "#3E4BBC";
    borderColorStyle = outline ? borderColor || backgroundColor : "#fff";
    errorColor = error ? "#dc3545" : backgroundColor;
  }

  let borderRadius = rounded ? 8 : 0;
  let borderWidthStyle = outline ? borderWidth || 1 : 0;
  let hoverBackgroundColor = COLORS.PRIMARY200;
  let iconMargin = icon
    ? iconPosition === "left" || iconPosition === "right"
      ? 5
      : 0
    : 0;

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <Pressable
      disabled={disabled}
      hitSlop={8}
      style={({ pressed }) => [{ opacity: pressed || disabled ? 0.5 : 1.0 }]}
      onPress={onPress}
      onHoverIn={handleMouseEnter}
      onHoverOut={handleMouseLeave}
    >
      <View
        style={[
          {
            backgroundColor: isHovered ? COLORS.PRIMARY200 : backgroundColor,
            borderRadius: borderRadius,
            borderWidth: borderWidthStyle,
            borderColor: borderColorStyle,
            paddingHorizontal: 10,
            paddingVertical: 5,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            ...containerStyle,
          },
          disabled && disabledStyle,
          error && { backgroundColor: errorColor },
        ]}
      >
        <Icon
          name={leftIcon}
          size={iconSize}
          color={iconColor}
          style={{ marginLeft: iconMargin }}
        />
        {title && (
          <Text
            style={[
              {
                color: textColor,
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "center",
                ...textStyle,
              },
            ]}
          >
            {title}
          </Text>
        )}
        {rightIcon && (
          <Icon
            name={rightIcon}
            size={iconSize}
            color={iconColor}
            style={{ marginLeft: iconMargin }}
          />
        )}
      </View>
    </Pressable>
  );
};

export const MainButton = ({
  title,
  leftIcon,
  rightIcon,
  onPress,
  disabled,
  hovered,
  selected,
  showTooltip,
  loading,
  type = "primary",
}) => {
  let backgroundColor;
  let textColor;
  let borderColorStyle;
  let leftIconSize;
  let rightIconSize;
  let iconColor = "black";
  let hoverBackgroundColor = COLORS.PRIMARY100;
  let borderRadius;
  if (type === "primary") {
    backgroundColor =
      selected === title ? COLORS.PRIMARY200 : disabled ? "#ccc" : "#fff";
    textColor = disabled ? "#999" : "#fff";
    borderRadius = 15;
    borderColorStyle = "#fff";
    leftIconSize = 24;
    rightIconSize = 16;
  } else if (type === "secondary") {
    backgroundColor = disabled ? "#f8f9fa" : "#6c757d";
    textColor = disabled ? "#999" : "#fff";
    borderColorStyle = "#fff";
    leftIconSize = 24;
  } else if (type === "tertiary") {
    backgroundColor = disabled ? "#f8f9fa" : "transparent";
    textColor = disabled ? "#999" : "#3E4BBC";
    borderColorStyle = "#fff";
    leftIconSize = 24;
  }

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <Pressable
      disabled={disabled}
      hitSlop={8}
      style={({ pressed }) => [{ opacity: pressed || disabled ? 0.5 : 1.0 }]}
      onPress={onPress}
      onHoverIn={handleMouseEnter}
      onHoverOut={handleMouseLeave}
    >
      <View
        style={[
          {
            backgroundColor: isHovered ? hoverBackgroundColor : backgroundColor,
            borderRadius: borderRadius,
            //borderWidth: borderWidthStyle,
            //borderColor: borderColorStyle,
            paddingHorizontal: 10,
            paddingVertical: 5,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          },
          disabled && disabledStyle,
        ]}
      >
        {leftIcon && (
          <Icon
            name={leftIcon}
            size={leftIconSize}
            color={iconColor}
            style={{ flex: 0.1 }}
          />
        )}
        {title && (
          <Text
            style={[
              globalStyles.bodySmallRegular,
              { marginLeft: 10, flex: 0.8 },
            ]}
          >
            {title}
          </Text>
        )}
        {rightIcon && (
          <Icon
            name={rightIcon}
            size={rightIconSize}
            color={iconColor}
            style={{ flex: 0.1 }}
          />
        )}
      </View>
      {showTooltip && (
        <Tooltip
          text={
            title === "Twitter"
              ? "This is your Twitter page"
              : title === "Instagram"
              ? "This is your Instagram page"
              : title === "Linkedin"
              ? "This is your Linkedin page"
              : "This will open a page of your choice"
          }
        />
      )}
    </Pressable>
  );
};
export const ImageButton = ({
  title,
  onPress,
  source,
  disabled,
  selected,
  showTooltip,
  type = "primary",
}) => {
  let backgroundColor;
  let rightIconSize;
  let iconColor = "black";
  let hoverBackgroundColor = COLORS.PRIMARY100;
  let borderRadius;
  if (type === "primary") {
    backgroundColor =
      selected === title ? COLORS.PRIMARY200 : disabled ? "#ccc" : "#fff";
    borderRadius = 15;
    rightIconSize = 16;
  }
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <Pressable
      disabled={disabled}
      hitSlop={8}
      style={({ pressed }) => [{ opacity: pressed || disabled ? 0.5 : 1.0 }]}
      onPress={onPress}
      onHoverIn={handleMouseEnter}
      onHoverOut={handleMouseLeave}
    >
      <View
        style={[
          {
            backgroundColor: isHovered ? hoverBackgroundColor : backgroundColor,
            borderRadius: borderRadius,
            paddingHorizontal: 10,
            paddingVertical: 5,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          },
          disabled && disabledStyle,
        ]}
      >
        <Image
          style={{
            width: 24,
            height: 24,
            marginRight: 2,
            borderRadius: 3,
          }}
          source={source}
        />
        {title && (
          <Text
            style={[
              globalStyles.bodySmallRegular,
              { marginLeft: 10, flex: 0.9 },
            ]}
          >
            {title}
          </Text>
        )}
        {
          <Icon
            name={"open-outline"}
            size={16}
            color={iconColor}
            style={{ flex: 0.1 }}
          />
        }
      </View>
      {showTooltip && (
        <Tooltip
          text={
            title === "Twitter"
              ? "This is your Twitter page"
              : title === "YouTube"
              ? "This is your YouTube page"
              : title === "Linkedin"
              ? "This is your Linkedin page"
              : title === "Facebook"
              ? "This is your Facebook page"
              : "This will open a page of your choice"
          }
        />
      )}
    </Pressable>
  );
};
export const ButtonGroup = ({ button1, button2, mode }) => {
  const [selectedButton, setSelectedButton] = useState(button1);

  const handleButtonPress = (buttonName) => {
    setSelectedButton(buttonName);
    if (buttonName === "Monthly") {
      mode("month");
    } else if (buttonName === "Annually") {
      mode("year");
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[
          styles.button,
          selectedButton === button1 && styles.selectedButton,
          { borderTopLeftRadius: 8, borderBottomLeftRadius: 8 },
        ]}
        onPress={() => handleButtonPress(button1)}
      >
        <Text
          style={[
            styles.buttonText,
            selectedButton === button1 && styles.selectedButtonText,
          ]}
        >
          {button1}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.button,
          selectedButton === button2 && styles.selectedButton,
          { borderTopRightRadius: 8, borderBottomRightRadius: 8 },
        ]}
        onPress={() => handleButtonPress(button2)}
      >
        <Text
          style={[
            styles.buttonText,
            selectedButton === button2 && styles.selectedButtonText,
          ]}
        >
          {button2}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
