import { Dimensions, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import globalStyles from "../../constants/globalStyles";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();

  const [period, setPeriod] = useState("month");

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View
      style={[
        styles.container,
        { paddingHorizontal: screenWidth > 800 ? 44 : 20 },
      ]}
    >
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Frequently Asked Questions"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Q: What is this product?"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "A: Our product is a learning center that can be integrated into your website, providing users with access to tutorials and resources to help them use your website more effectively."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Q: How does it work?"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "A: Our product is designed to be easily integrated into your website using our software development kit (SDK). Simply add the SDK to your website and our learning center will be accessible through a button just like in our home page demo. From there, you can manage and add tutorials without any additional development effort."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Q: Do I need technical skills to use this product?"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "A: No, our product is designed to be user-friendly and easy to use for people with any level of technical expertise."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Q: Can I customize the tutorials for my specific website?"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "A: Yes, you can add tutorials that are tailored specifically to your website and its features, and manage them through the product's simple interface."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Q: What kind of support do you offer?"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "A: We offer customer support through email and phone, as well as a help center with resources and answers to common questions. According to your subscription we may or may not offer 24/7 support."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Q: How much does it cost?"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "A: Our pricing varies depending on the feature set and monthly unique users of your website. For more information on pricing, please visit our pricing page or contact us to request a quote."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Q: Is there a free trial available?"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "A: Yes, we offer a free trial period so you can try out our product before committing to a subscription. Contact us for more information on how to get started."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Q: Is my data secure?"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "A: Yes, we take data security and privacy seriously and use industry-standard encryption and security measures to protect your information."
          }
        </Text>
      </View>
    </View>
  );
};

export default FAQ;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEF1F5",
    paddingVertical: 70,
  },
  featureContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    marginTop: 50,
    width: "100%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  heroMobileContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  leftContainer: {
    //maxWidth: 600,
    marginBottom: 20,
  },
  rightContainer: {
    justifyContent: "flex-start",
  },
  image: {
    height: 500,
    width: 500,
    resizeMode: "contain",
  },
});
