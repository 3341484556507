import { Modal, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import { styles } from "./styles";
import { CustomButton } from "../Button";
import { COLORS } from "../../constants/colors";
import { AddStepBody, ModalFooter, ModalHeader } from "../Modal";
const steps = [
  { id: 1, title: "Step 1", description: "This is card 1" },
  { id: 2, title: "Step 2", description: "This is card 2" },
  { id: 3, title: "Step 3", description: "This is card 3" },
  { id: 4, title: "Step 4", description: "This is card 4" },
  { id: 5, title: "Step 5", description: "This is card 5" },
  { id: 6, title: "Step 6", description: "This is card 6" },
];
const Steps = () => {
  return (
    <View style={styles.container}>
      {steps.map((item) => (
        <StepRow item={item} />
      ))}
    </View>
  );
};
export default Steps;

const StepRow = ({ item }) => {
  return (
    <View style={styles.itemContainer}>
      <TouchableOpacity
        style={{
          padding: 9,
          backgroundColor: "white",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <CustomButton
            /* onPress={editPress} */
            rounded
            leftIcon={"reorder-two-outline"}
            iconColor={COLORS.GREY_DARK}
            containerStyle={styles.sortButton}
          />

          <Text style={{ color: COLORS.BLACK }}>{item.title}</Text>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <CustomButton
            rounded
            leftIcon={"ios-create"}
            iconColor={COLORS.PRIMARY}
            containerStyle={styles.editButton}
            textStyle={{ color: COLORS.PRIMARY }}
          />
          <View style={{ width: 5 }}></View>
          <CustomButton
            rounded
            leftIcon={"trash"}
            iconColor={COLORS.PRIMARY}
            containerStyle={styles.editButton}
            textStyle={{ color: COLORS.PRIMARY }}
          />
          <View style={{ width: 5 }}></View>
          <CustomButton
            rounded
            leftIcon={"ios-copy"}
            iconColor={COLORS.PRIMARY}
            containerStyle={styles.editButton}
            textStyle={{ color: COLORS.PRIMARY }}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export const AddStepModal = ({ visible, setVisible, data }) => {
  const handleSave = (section) => {
    null;
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <Modal visible={visible} animationType="none" transparent={true}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <ModalHeader text={"Add Step"} onClose={onClose} />
          <AddStepBody data={data}/>
          <ModalFooter onPress={handleSave} onClose={onClose} />
        </View>
      </View>
    </Modal>
  );
};