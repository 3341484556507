import { View } from "react-native";
import React from "react";
import { TextButton } from "../Button";
import { useTranslation } from "react-i18next";
import ROUTES from "../../constants/routes";
import { useNavigation } from "@react-navigation/native";

const NavBar = ({ style }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <View style={[{ flexDirection: "row", marginRight: 20 }, style]}>
      <TextButton
        text={t("footer.why")}
        onPress={() => navigation.navigate(ROUTES.WHY)}
        textStyle
      />
      <View style={{ width: 10 }} />
      <TextButton
        text={t("footer.how")}
        onPress={() => navigation.navigate(ROUTES.HOW)}
      />
      <View style={{ width: 10 }} />
      {/*  <TextButton text={t("header.customer")} onPress={productRef} />
      <View style={{ width: 10 }} /> */}
      <TextButton
        text={t("header.pricing")}
        onPress={() => navigation.navigate(ROUTES.PRICING)}
      />
      {/* <View style={{ width: 10 }} />
      <TextButton text={t("header.aboutUs")} onPress={aboutRef} /> */}
    </View>
  );
};

export default NavBar;
