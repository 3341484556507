import { View, Text, Pressable, Image } from "react-native";
import React from "react";
import { COLORS } from "../../constants/colors";
import { CustomButton } from "../Button";
import { styles } from "./styles";
import CarouselPagination from "../Carousel/carouselPagination";

const Card = ({
  data,
  stepCount = 5,
  activeStep = 0,
  addPress,
  editPress,
  noEdit,
  onPress,
  noPress,
  setItem,
}) => {
  const CardPressed = () => {
    if (!noPress) {
      onPress(true);
      setItem(data);
    }
  };
  return (
    <Pressable
      key={data.tutorialId}
      onPress={CardPressed}
      style={({ pressed }) => [{ opacity: !noPress && pressed ? 0.7 : 1.0 }]}
    >
      <View style={styles.container}>
        {/* Image */}
        <CardImage
          url={
            data.tutorialSteps.length
              ? data.imageUrl
              : require("../../../assets/images/videoTutorial.png")
          }
        />
        {/* Steps Count & Category Button */}
        <View style={styles.rowContainer}>
          {data.tutorialSteps.length > 0 ? (
            <Text style={styles.stepCount}>
              {data.tutorialSteps.length} STEPS
            </Text>
          ) : (
            <Text style={styles.stepCount}>VIDEO TUTORIAL</Text>
          )}
          {noEdit && data.category && (
            <CustomButton
              title={data.category}
              onPress={addPress}
              rounded
              containerStyle={styles.badge}
              textStyle={{ color: COLORS.PRIMARY }}
            />
          )}
          {!noEdit && (
            <CustomButton
              title="Add Category"
              onPress={addPress}
              rounded
              leftIcon={"add"}
              iconColor={COLORS.PRIMARY}
              containerStyle={styles.addButton}
              textStyle={{ color: COLORS.PRIMARY }}
            />
          )}
        </View>
        {/* Tutorial Name & Edit Button */}
        <View style={styles.rowContainer}>
          <Text style={styles.titleText}>{data.title}</Text>
          {!noEdit && (
            <CustomButton
              onPress={editPress}
              rounded
              leftIcon={"md-create"}
              iconColor={COLORS.PRIMARY}
              containerStyle={styles.editButton}
              textStyle={{ color: COLORS.PRIMARY }}
            />
          )}
        </View>
        {/* Steps Count as Carousel */}
        {!noEdit && (
          <CarouselPagination count={stepCount} active={activeStep} />
        )}
      </View>
    </Pressable>
  );
};

export default Card;

const CardImage = ({ url }) => {
  return (
    <Image
      style={{
        height: 133,
        width: 218,
        backgroundColor: COLORS.WHITE,
        borderRadius: 30,
        //borderWidth: 2,
        borderColor: COLORS.BORDER,
        resizeMode: "contain",
      }}
      source={url}
    />
  );
};
