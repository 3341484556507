import { createNativeStackNavigator } from "@react-navigation/native-stack";
import MainScreen from "../screens/MainScreen";
import { useTranslation } from "react-i18next";
import ROUTES from "../constants/routes";
import DemoScreen from "../screens/DemoScreen";
import DashboardScreen from "../screens/DashboardScreen";
import PricingScreen from "../screens/PricingScreen";
import SignInScreen from "../screens/SignInScreen";
import BetaScreen from "../screens/BetaScreen";
import TermsScreen from "../screens/TermsScreen";
import PrivacyScreen from "../screens/PrivacyScreen";
import CookieScreen from "../screens/CookieScreen";
import FAQScreen from "../screens/FAQScreen";
import ContactScreen from "../screens/ContactScreen";
import WhyScreen from "../screens/WhyScreen";
import HowScreen from "../screens/HowScreen";

const Stack = createNativeStackNavigator();

const MainStackNavigation = () => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      id="MainNavigation"
      initialRouteName={ROUTES.MAIN}
      screenOptions={({ route, navigation }) => {
        ({ headerShown: false });
      }}
    >
      <Stack.Screen
        name={ROUTES.MAIN}
        component={MainScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.DEMO}
        component={DemoScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.PRICING}
        component={PricingScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.DASHBOARD}
        component={DashboardScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.LOGIN}
        component={SignInScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.BETA}
        component={BetaScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.TERMS}
        component={TermsScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.PRIVACY}
        component={PrivacyScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.COOKIE}
        component={CookieScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.FAQ}
        component={FAQScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.CONTACT}
        component={ContactScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.WHY}
        component={WhyScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.HOW}
        component={HowScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

export default MainStackNavigation;
