import { Dimensions, Image, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { COLORS } from "../../constants/colors";
import globalStyles from "../../constants/globalStyles";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/Ionicons";
import { CustomButton } from "../Button";
import Testimonial from "../Testimonial";

const Features = ({
  type = "DEFAULT",
  order = 1,
  title,
  subtitle,
  imageSource = require("../../../assets/images/feature1.png"),
  featurePoint1,
  featurePoint2,
  featurePoint3,
}) => {
  const { t } = useTranslation();

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("window").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: screenWidth > 800 ? COLORS.WHITE : COLORS.PRIMARY700,
        },
      ]}
    >
      {order === 1 && (
        <View style={{ marginBottom: 50 }}>
          <Text
            style={[
              screenWidth > 800
                ? globalStyles.headingXLarge
                : globalStyles.headingXLarge,
              {
                textAlign: "center",
                color: screenWidth <= 800 && COLORS.WHITE,
              },
            ]}
          >
            {t("features.title")}
          </Text>
          <Text
            style={[
              screenWidth > 800
                ? globalStyles.bodyLargeRegular
                : globalStyles.bodyLargeRegular,
              {
                textAlign: "center",
                marginTop: 10,
                color: screenWidth <= 800 && COLORS.WHITE,
              },
            ]}
          >
            {t("features.subtitle")}
          </Text>
        </View>
      )}
      <View
        style={[
          styles.featureContainer,
          {
            flexDirection:
              screenWidth <= 800
                ? "column"
                : type == "DEFAULT"
                ? "row"
                : "row-reverse",
          },
        ]}
      >
        <View
          style={[
            styles.leftContainer,
            {
              width: screenWidth > 800 ? "50%" : "100%",
              paddingHorizontal: 10,
            },
          ]}
        >
          <Text
            style={{
              ...globalStyles.headingLarge,
              textAlign: screenWidth > 800 ? "left" : "center",
              color: screenWidth <= 800 && COLORS.WHITE,
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              ...globalStyles.bodyMediumRegular,
              textAlign: screenWidth > 800 ? "left" : "center",
              fontSize: screenWidth > 800 ? 20 : 16,
              color: screenWidth <= 800 && COLORS.WHITE,

              marginTop: 25,
            }}
          >
            {subtitle}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 25,
            }}
          >
            <Icon
              name={"checkmark-circle-sharp"}
              size={24}
              color={screenWidth > 800 ? COLORS.PRIMARY : COLORS.WHITE}
            />
            <Text
              style={{
                ...globalStyles.bodyLargeSemiBold,
                textAlign: screenWidth > 800 ? "left" : "left",
                marginLeft: 10,
                color: screenWidth <= 800 && COLORS.WHITE,
              }}
            >
              {featurePoint1}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 25,
            }}
          >
            <Icon
              name={"checkmark-circle-sharp"}
              size={24}
              color={screenWidth > 800 ? COLORS.PRIMARY : COLORS.WHITE}
            />
            <Text
              style={{
                ...globalStyles.bodyLargeSemiBold,
                textAlign: screenWidth > 800 ? "left" : "left",
                marginLeft: 10,
                color: screenWidth <= 800 && COLORS.WHITE,
              }}
            >
              {featurePoint2}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 25,
            }}
          >
            <Icon
              name={"checkmark-circle-sharp"}
              size={24}
              color={screenWidth > 800 ? COLORS.PRIMARY : COLORS.WHITE}
            />
            <Text
              style={{
                ...globalStyles.bodyLargeSemiBold,
                textAlign: screenWidth > 800 ? "left" : "left",
                marginLeft: 10,
                color: screenWidth <= 800 && COLORS.WHITE,
              }}
            >
              {featurePoint3}
            </Text>
          </View>
          <View style={{ alignSelf: "center" }}>
            <CustomButton
              onPress={null}
              rightIcon={"arrow-forward"}
              title={t("features.tryNow")}
              rounded
              iconPosition="right"
              containerStyle={{
                height: 36,
                width: 108,
                backgroundColor: "transparent",
                alignItems: "center",
                marginTop: 25,
              }}
              textStyle={{ color: COLORS.WHITE }}
              iconColor={COLORS.WHITE}
            />
          </View>
          {/*           <Testimonial
            text={
              "“We used tutorials to increase our feature adoption and we got great feedbacks from our customers. We also use LearnBase to analyze feature success.”"
            }
            image={require("../../../assets/images/avatar.jpg")}
            name={"Kutay Zeki"}
            title={"Product Manager"}
          /> */}
        </View>
        <View style={styles.rightContainer}>
          <Image
            style={{
              ...styles.image,
              width: screenWidth > 800 ? screenWidth * 0.48 : screenWidth * 0.9,
              alignSelf: screenWidth <= 800 && "center",
              height:
                screenWidth > 800
                  ? (screenWidth * 0.48 * 9) / 16
                  : screenWidth * 0.9,
              aspectRatio: 16 / 9,
              borderRadius: 15,
              resizeMode: "contain",
            }}
            source={imageSource}
          />
          {/* Visual */}
        </View>
      </View>
    </View>
  );
};

export default Features;

const styles = StyleSheet.create({
  container: {
    padding: 44,
    paddingVertical: 40,
  },
  featureContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    marginTop: 50,
    width: "100%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  heroMobileContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  leftContainer: {
    marginBottom: 20,
  },
  rightContainer: {
    justifyContent: "flex-start",
  },
  image: {
    resizeMode: "contain",
  },
});
