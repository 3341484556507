import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const styles = StyleSheet.create({
  modalContainer: {
    height: "90%",
    width: "90%",
    maxHeight: 500,
    maxWidth: 400,
    position: "absolute",
    right: 20,
    bottom: 50,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    justifyContent: "space-between",
    width: "100%",
    /* height: "100%", */
    padding: 20,
    borderRadius: 8,
    elevation: 5,
    flex: 1,
  },
  input: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
  },
  footerContainer: {
    flexDirection: "row",
    width: "100%",
    height: 84,
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerButtonContainer: {
    flexDirection: "row",
    height: 84,
    justifyContent: "flex-end",
    alignItems: "center",
  },
});
