import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "#fff",
    justifyContent: "space-between",
    maxWidth: 817,
    maxHeight: 646,
    width: "100%",
    height: "100%",
    padding: 20,
    borderRadius: 8,
    elevation: 5,
  },
  bodyContainer: { flex: 1, flexDirection: "row" },
  leftContainer: {
    height: "100%",
    width: "40%",
    alignItems: "baseline",
    justifyContent: "center",
  },
  rightContainer: {
    padding: 12,
    flex: 1,
  },
  button: {
    position: "absolute",
    width: 60,
    height: 60,
    borderRadius: 30,
    alignItems: "center",
    justifyContent: "center",
    bottom: 20,
    right: 20,
    elevation: 5,
  },
  text: {
    fontFamily: "LindseyRegular",
    fontWeight: "bold",
    fontSize: 32,
  },
});
