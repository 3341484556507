import { View, Text, TextInput } from "react-native";
import React, { useState } from "react";
import { CustomButton } from "../Button";
import { COLORS } from "../../constants/colors";
import { styles } from "./styles";
import HelperText from "../HelperText";
import Card from "../Card/Card";
import Steps, { AddStepModal } from "../Step";
import SearchBar from "../SearchBar";
export const ModalHeader = ({ text, onClose, noTitle, onSearch }) => {
  return (
    <View style={styles.container}>
      <SearchBar onSearch={onSearch} />

      {!noTitle && (
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{text}</Text>
        </View>
      )}
      <CustomButton
        onPress={onClose}
        leftIcon={"close"}
        rounded
        containerStyle={{
          height: 32,
          width: 32,
          backgroundColor: COLORS.GREY_SOFT,
          marginRight: 20,
        }}
        iconColor={COLORS.BLACK_SOFT}
      />
    </View>
  );
};
export const ModalFooter = ({
  onPress,
  onClose,
  helperText,
  cancelTitle = "Cancel",
  actionTitle = "Save",
}) => {
  return (
    <View style={styles.footerContainer}>
      {helperText && <HelperText type="info" text={helperText} />}
      <CustomButton
        title={cancelTitle}
        onPress={onClose}
        styleType={"tertiary"}
        rounded
        textStyle={{ color: COLORS.TEXT_DARK }}
      />
      <View style={{ width: 10 }}></View>
      <CustomButton
        title={actionTitle}
        onPress={onPress}
        rounded
        containerStyle={{ height: 48, minWidth: 69 }}
      />
    </View>
  );
};
export const ModalBody = ({ onPress, onClose, helperText }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [title, setTitle] = useState("");

  return (
    <View style={styles.bodyContainer}>
      <View style={styles.leftContainer}>
        <TextInput
          style={styles.input}
          placeholder={"Title"}
          maxLength={100}
          onChangeText={(text) => setTitle(text)}
          value={title}
        />
      </View>
      <View style={styles.rightContainer}>
        <Steps />
        <CustomButton
          title={"Add Step"}
          leftIcon={"add"}
          styleType={"primary"}
          rounded
          onPress={() => setModalVisible(true)}
          containerStyle={styles.button}
          textStyle={{ fontSize: 12 }}
        />
      </View>
      <AddStepModal visible={modalVisible} setVisible={setModalVisible} />
    </View>
  );
};

export const AddStepBody = ({ onPress, onClose, helperText }) => {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  return (
    <View style={styles.addStepBody}>
      <TextInput
        style={styles.input}
        placeholder={"Title"}
        maxLength={100}
        onChangeText={(text) => setTitle(text)}
        value={title}
      />
      <TextInput
        style={[styles.input, { maxWidth: 700 }]}
        placeholder={"Description"}
        maxLength={400}
        onChangeText={(text) => setDesc(text)}
        value={desc}
      />
      {/* Media Upload */}
    </View>
  );
};
