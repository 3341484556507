import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const styles = StyleSheet.create({
  container: {},
  itemContainer: {
    borderWidth: 2,
    borderColor: COLORS.BORDER,
    borderRadius: 6,
    marginBottom: 5,
  },
  editButton: {
    height: 28,
    width: 28,
    borderRadius: 6,
    backgroundColor: "#DADCEE",
    padding: 10,
  },
  sortButton: {
    backgroundColor: "transparent",
    height: 28,
    width: 28,
    borderRadius: 6,
    padding: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "#fff",
    justifyContent: "space-between",
    maxWidth: 817,
    maxHeight: 646,
    width: "100%",
    height: "100%",
    padding: 20,
    borderRadius: 8,
    elevation: 5,
  },
  input: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
  },
  editButton: {
    height: 28,
    width: 28,
    borderRadius: 6,
    backgroundColor: "#DADCEE",
    padding: 10,
  },
});
