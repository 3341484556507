import { Dimensions, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import globalStyles from "../../constants/globalStyles";
import { useTranslation } from "react-i18next";

const How = () => {
  const { t } = useTranslation();

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View
      style={[
        styles.container,
        { paddingHorizontal: screenWidth > 800 ? 44 : 20 },
      ]}
    >
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"How it Works"}
        </Text>
        <Text
          style={{
            ...globalStyles.headingLarge,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"1. Integration"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "To get started with LearnBase, you'll need to integrate our software development kit (SDK) into your website. This is a simple process that can be completed easily following our guideline. Once integrated, the LearnBase learning center will be accessible to your website users through the Learning Center button."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.headingLarge,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"2. Managing Tutorials and Links"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "Once the LearnBase learning center is integrated into your website, you can begin managing your tutorials and URL links. From your LearnBase dashboard, you can create, edit, and delete tutorials, as well as add URL links to important pages and documents on your website."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.headingLarge,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"3. Customization"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "LearnBase allows you to customize your tutorials and links to meet the needs of your website users. You can add images, videos, and text to your tutorials, as well as categorize them to make them easy to find. You can also customize the appearance of the learning center to match the look and feel of your website."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.headingLarge,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"4. User Access"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "Once you have created and customized your tutorials and links, your website users can access them through the LearnBase learning center. They simply need to click on the Learning Center button to open, and then select the tutorial or link they need."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.headingLarge,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"5. No Development Effort"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "One of the biggest benefits of LearnBase is that after the initial integration, no additional development effort is required. This means that anyone on your team, whether it's operations, marketing, or any other department, can manage and add tutorials and links without needing to involve your web developer."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.headingLarge,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"6. Support"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "We take customer support seriously at LearnBase. If you encounter any issues or have any questions about using our product, our support team is available to help. You can contact us via email or phone, and we also offer a help center with resources and answers to common questions."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.headingMedium,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {
            "In summary, LearnBase is an easy-to-use, customizable, and secure learning center and knowledge hub for your website users. With our product, you can provide your users with the resources and knowledge they need to effectively use your website, while also minimizing the need for ongoing development effort. Ultimately, this leads to a more successful online business, and allows teams across your organization to easily manage and add tutorials and links."
          }
        </Text>
      </View>
    </View>
  );
};

export default How;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEF1F5",
    paddingVertical: 70,
  },
  featureContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    marginTop: 50,
    width: "100%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  heroMobileContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  leftContainer: {
    //maxWidth: 600,
    marginBottom: 20,
  },
  rightContainer: {
    justifyContent: "flex-start",
  },
  image: {
    height: 500,
    width: 500,
    resizeMode: "contain",
  },
});
