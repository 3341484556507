import { Dimensions, Text, View } from "react-native";
import React, { useState } from "react";
import { COLORS } from "../../constants/colors";
import { HeroAction } from "../Button";
import Icon from "react-native-vector-icons/Ionicons";
import { useTranslation } from "react-i18next";
import globalStyles from "../../constants/globalStyles";
import { useNavigation } from "@react-navigation/native";
import { ROUTES } from "../../constants";
export const StandartPrice = ({ type, price, per, setSelectedPlan }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });
  return (
    <View
      style={{
        width: screenWidth > 850 ? "32%" : "100%",
        maxWidth: 500,
        padding: 16,
        paddingVertical: 30,
        borderRadius: 12,
        marginBottom: 20,
        alignSelf: "center",
        backgroundColor: COLORS.WHITE,
      }}
    >
      {/* title */}
      <Text
        style={[
          globalStyles.headingMedium,
          { textAlign: "center", marginBottom: 15 },
        ]}
      >
        {t("pricing.standart")}
      </Text>
      {/* Subtitle */}
      <Text
        style={[
          globalStyles.bodyLargeRegular,
          { textAlign: "center", marginBottom: 15 },
        ]}
      >
        {t("pricing.standartSubtext")}
      </Text>
      {/* Price */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            textAlign: "center",
            fontSize: screenWidth > 1000 ? 40 : 32,
            marginBottom: 12,
            color: COLORS.TEXT_PRIMARY,
          }}
        >
          {price}
        </Text>
        <Text
          style={{
            textAlign: "center",
            fontSize: screenWidth > 1000 ? 20 : 16,
            color: COLORS.TEXT_PRIMARY,
          }}
        >
          {` $ /${per === "/month" ? t("pricing.month") : t("pricing.year")}`}
        </Text>
      </View>
      <Text
        style={[
          globalStyles.bodyMediumRegular,
          { textAlign: "center", marginBottom: 15, color: "#6E6E6E" },
        ]}
      >
        {t("pricing.upTo")}
        {" 100 "}
        {t("pricing.MAU")}
      </Text>
      <HeroAction
        title={t("pricing.selectPlan")}
        onPress={() =>
          navigation.navigate({ name: ROUTES.LOGIN, params: { item: price } })
        }
        style={{
          backgroundColor: type === "DEFAULT" ? COLORS.WHITE : COLORS.PRIMARY,
          borderRadius: 8,
          height: 60,
          width: 200,
          alignSelf: "center",
          borderWidth: 1,
          borderColor: COLORS.PRIMARY,
          marginBottom: 20,
        }}
        textColor={type === "DEFAULT" ? COLORS.PRIMARY : COLORS.WHITE}
      />
      <Text
        style={[
          globalStyles.bodyLargeRegular,
          { textAlign: "center", marginBottom: 15, color: "#6E6E6E" },
        ]}
      >
        {"14-day free trial "}
      </Text>
      <View
        style={{
          height: 350,
          borderTopWidth: 1,
          borderColor: "#E0E3EC",
          paddingTop: 10,
          marginTop: 10,
        }}
      >
        <Text
          style={[
            globalStyles.headingMedium,
            { textAlign: "center", marginBottom: 15 },
          ]}
        >
          {t("pricing.includes")}
        </Text>
        <Benefits type={"DEFAULT"} text={"Up to 100 monthly active users"} />
        <Benefits type={"DEFAULT"} text={"Unlimited tutorials & links"} />
        <Benefits type={"DEFAULT"} text={"Unlimited Social Links"} />
        <Benefits type={"DEFAULT"} text={"Custom branding"} />
        <Benefits type={"DEFAULT"} text={"Email Support"} />
      </View>
      <HeroAction
        title={t("pricing.selectPlan")}
        onPress={() =>
          navigation.navigate({ name: ROUTES.LOGIN, params: { item: price } })
        }
        style={{
          backgroundColor: type === "DEFAULT" ? COLORS.WHITE : COLORS.PRIMARY,
          borderRadius: 8,
          height: 60,
          width: 200,
          alignSelf: "center",
          borderWidth: 1,
          borderColor: COLORS.PRIMARY,
          marginBottom: 20,
        }}
        textColor={type === "DEFAULT" ? COLORS.PRIMARY : COLORS.WHITE}
      />
    </View>
  );
};
export const ExtendedPrice = ({ type, price, per, setSelectedPlan }) => {
  const navigation = useNavigation();

  const { t } = useTranslation();

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });
  return (
    <View
      style={{
        width: screenWidth > 850 ? "32%" : "100%",
        maxWidth: 500,
        padding: 16,
        paddingVertical: 30,
        borderRadius: 12,
        marginBottom: 20,
        alignSelf: "center",
        backgroundColor: COLORS.WHITE,
      }}
    >
      {/* title */}
      <Text
        style={[
          globalStyles.headingMedium,
          { textAlign: "center", marginBottom: 15 },
        ]}
      >
        {t("pricing.extended")}
      </Text>
      {/* discount */}
      {/* {per === "/year" && (
        <View
          style={{
            backgroundColor: COLORS.SUCCESS,
            position: "absolute",
            top: 5,
            right: 5,
            paddingHorizontal: 15,
            borderRadius: 20,
            shadowColor: COLORS.BLACK,
            shadowOffset: { width: 2, height: 3 },
            shadowOpacity: 0.2,
            shadowRadius: 2,
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 24,

              color: COLORS.TEXT_SECONDARY,
            }}
          >
            Best
          </Text>
        </View>
      )} */}
      {/* Subtitle */}
      <Text
        style={[
          globalStyles.bodyLargeRegular,
          { textAlign: "center", marginBottom: 15 },
        ]}
      >
        {t("pricing.extendedSubtext")}
      </Text>
      {/* Price */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            textAlign: "center",
            fontSize: screenWidth > 1000 ? 40 : 32,
            marginBottom: 12,
            color: COLORS.TEXT_PRIMARY,
          }}
        >
          {price}
        </Text>
        <Text
          style={{
            textAlign: "center",
            fontSize: screenWidth > 1000 ? 20 : 16,
            color: COLORS.TEXT_PRIMARY,
          }}
        >
          {` $ /${per === "/month" ? t("pricing.month") : t("pricing.year")}`}
        </Text>
      </View>
      <Text
        style={[
          globalStyles.bodyMediumRegular,
          { textAlign: "center", marginBottom: 15, color: "#6E6E6E" },
        ]}
      >
        {t("pricing.upTo")}
        {" 20,000 "}
        {t("pricing.MAU")}
      </Text>
      <HeroAction
        title={t("pricing.selectPlan")}
        onPress={() =>
          navigation.navigate({ name: ROUTES.LOGIN, params: { item: price } })
        }
        style={{
          backgroundColor: type === "DEFAULT" ? COLORS.WHITE : COLORS.PRIMARY,
          borderRadius: 8,
          height: 60,
          width: 200,
          alignSelf: "center",
          borderWidth: 1,
          borderColor: COLORS.PRIMARY,
          marginBottom: 20,
        }}
        textColor={type === "DEFAULT" ? COLORS.PRIMARY : COLORS.WHITE}
      />
      <Text
        style={[
          globalStyles.bodyLargeRegular,
          { textAlign: "center", marginBottom: 15, color: "#6E6E6E" },
        ]}
      >
        {"14-day free trial "}
      </Text>
      <View
        style={{
          height: 350,
          borderTopWidth: 1,
          borderColor: "#E0E3EC",
          paddingTop: 10,
          marginTop: 10,
        }}
      >
        <Text
          style={[
            globalStyles.headingMedium,
            { textAlign: "center", marginBottom: 15 },
          ]}
        >
          {t("pricing.includes2")}
        </Text>
        <Benefits type={"DEFAULT"} text={"Up to 20,000 monthly active users"} />
        <Benefits type={"DEFAULT"} text={"User analytics"} />
        <Benefits type={"DEFAULT"} text={"Onboarding checklist"} />
        <Benefits type={"DEFAULT"} text={"Remove branding"} />
        <Benefits type={"DEFAULT"} text={"Phone Support"} />
      </View>
      <HeroAction
        title={t("pricing.selectPlan")}
        onPress={() =>
          navigation.navigate({ name: ROUTES.LOGIN, params: { item: price } })
        }
        style={{
          backgroundColor: type === "DEFAULT" ? COLORS.WHITE : COLORS.PRIMARY,
          borderRadius: 8,
          height: 60,
          width: 200,
          alignSelf: "center",
          borderWidth: 1,
          borderColor: COLORS.PRIMARY,
          marginBottom: 20,
        }}
        textColor={type === "DEFAULT" ? COLORS.PRIMARY : COLORS.WHITE}
      />
    </View>
  );
};
export const PremiumPrice = ({ type, price, per, setSelectedPlan }) => {
  const navigation = useNavigation();

  const { t } = useTranslation();

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });
  return (
    <View
      style={{
        width: screenWidth > 850 ? "32%" : "100%",
        maxWidth: 500,
        padding: 16,
        paddingVertical: 30,
        borderRadius: 12,
        marginBottom: 20,
        alignSelf: "center",
        backgroundColor: COLORS.WHITE,
      }}
    >
      {/* title */}
      <Text
        style={[
          globalStyles.headingMedium,
          { textAlign: "center", marginBottom: 15 },
        ]}
      >
        {t("pricing.premium")}
      </Text>
      {/* Subtitle */}
      <Text
        style={[
          globalStyles.bodyLargeRegular,
          { textAlign: "center", marginBottom: 15 },
        ]}
      >
        {t("pricing.premiumSubtext")}
      </Text>

      {/* Price */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            textAlign: "center",
            fontSize: screenWidth > 1000 ? 40 : 32,
            marginBottom: 12,
            color: COLORS.TEXT_PRIMARY,
          }}
        >
          {price}
        </Text>
        <Text
          style={{
            textAlign: "center",
            fontSize: screenWidth > 1000 ? 20 : 16,
            color: COLORS.TEXT_PRIMARY,
          }}
        >
          {` $ /${per === "/month" ? t("pricing.month") : t("pricing.year")}`}
        </Text>
      </View>
      <Text
        style={[
          globalStyles.bodyMediumRegular,
          { textAlign: "center", marginBottom: 15, color: "#6E6E6E" },
        ]}
      >
        {" Unlimited "}
        {t("pricing.MAU")}
      </Text>
      <HeroAction
        title={t("pricing.selectPlan")}
        onPress={() =>
          navigation.navigate({ name: ROUTES.LOGIN, params: { item: price } })
        }
        style={{
          backgroundColor: type === "DEFAULT" ? COLORS.WHITE : COLORS.PRIMARY,
          borderRadius: 8,
          height: 60,
          width: 200,
          alignSelf: "center",
          borderWidth: 1,
          borderColor: COLORS.PRIMARY,
          marginBottom: 20,
        }}
        textColor={type === "DEFAULT" ? COLORS.PRIMARY : COLORS.WHITE}
      />
      <Text
        style={[
          globalStyles.bodyLargeRegular,
          { textAlign: "center", marginBottom: 15, color: "#6E6E6E" },
        ]}
      >
        {"14-day free trial "}
      </Text>
      <View
        style={{
          height: 350,
          borderTopWidth: 1,
          borderColor: "#E0E3EC",
          paddingTop: 10,
          marginTop: 10,
        }}
      >
        <Text
          style={[
            globalStyles.headingMedium,
            { textAlign: "center", marginBottom: 15 },
          ]}
        >
          {t("pricing.includes3")}
        </Text>
        <Benefits type={"DEFAULT"} text={"Unlimited monthly active users"} />
        <Benefits type={"DEFAULT"} text={"Multiple products"} />
        <Benefits
          type={"DEFAULT"}
          text={
            "Enterprise account administration, including roles and permissions"
          }
        />
        <Benefits type={"DEFAULT"} text={"Single sign-on (SSO)"} />
        <Benefits type={"DEFAULT"} text={"Multi-language support"} />
        <Benefits type={"DEFAULT"} text={"Premium 24/7 support and services"} />
      </View>
      <HeroAction
        title={t("pricing.selectPlan")}
        onPress={() =>
          navigation.navigate({ name: ROUTES.LOGIN, params: { item: price } })
        }
        style={{
          backgroundColor: type === "DEFAULT" ? COLORS.WHITE : COLORS.PRIMARY,
          borderRadius: 8,
          height: 60,
          width: 200,
          alignSelf: "center",
          borderWidth: 1,
          borderColor: COLORS.PRIMARY,
          marginBottom: 20,
        }}
        textColor={type === "DEFAULT" ? COLORS.PRIMARY : COLORS.WHITE}
      />
    </View>
  );
};
const Benefits = ({ type, text }) => {
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "flex-start",
        width: "90%",
        maxWidth: 300,
        alignSelf: "center",
        marginVertical: 5,
      }}
    >
      <Icon
        name={"ios-checkmark-circle"}
        size={24}
        color={type === "DEFAULT" ? COLORS.PRIMARY : COLORS.GREY_DARK}
        style={{ alignSelf: "center", marginRight: 3 }}
      />
      <Text style={globalStyles.bodyLargeRegular}>{text}</Text>
    </View>
  );
};
