import React from "react";
import { View, Text, FlatList } from "react-native";
import Card from "../Card/Card";
import { styles } from "./styles";
import { useFetch } from "../../services/hooks";

const data = {
  pageNumber: 1,
  pageSize: 10,
  totalPages: 1,
  totalRecords: 3,
  data: [
    {
      tutorialId: "33a4c46a-9e99-4a97-b600-9ca480a2f0ac",
      title: "Create an account",
      category: "Onboarding",
      imageUrl: "https://i.imgur.com/BkyY3zA.png",
      tutorialType: 100,
      productName: "Getir Food",
      companyName: "Getir",
      tutorialSteps: [
        {
          id: "e17d8dc2-8542-40c1-874a-8595dd074593",
          title: "Start for free",
          description:
            'Go to our website and click on the "Start for free" button to create an account.',
          url: "https://i.imgur.com/R01Tg4P.gif",
          order: 0,
          tutorialName: "Create an account",
        },
        {
          id: "8cef14ac-6314-4827-a72d-fedb829289e0",
          title: "Find the best price",
          description: "Find the best package for your needs",
          url: "https://i.imgur.com/TFbRSgG.gif",
          order: 1,
          tutorialName: "Create an account",
        },
        {
          id: "7d009354-0b03-4fb9-80b2-35b15e066e50",
          title: "Create account",
          description:
            "Fill out your email address and let us get back to you!",
          url: "https://i.imgur.com/7gymDb8.gif",
          order: 2,
          tutorialName: "Create an account",
        },
      ],
    },
    {
      tutorialId: "1687c84e-1f6c-42f1-a8b9-dfd8349b3cc0",
      title: "Add thumbnail image",
      category: "Image",
      imageUrl: "https://i.imgur.com/vtGXLZV.png",
      tutorialType: 100,
      productName: "Getir Food",
      companyName: "Getir",
      tutorialSteps: [
        {
          id: "17c559f2-d852-4e9c-8d6e-ed3e478045fe",
          title: "Add Image",
          description: "You can add images to explain your website",
          url: "https://i.imgur.com/XWiJQ0F.png",
          order: 0,
          tutorialName: "Add thumbnail image",
        },
        {
          id: "31b5db0b-e457-4d69-ac47-70750974961a",
          title: "Or gif",
          description: "You can add gifs or videos as well!",
          url: "https://media.tenor.com/XYidKqUdCeMAAAAM/dog-running.gif",
          order: 1,
          tutorialName: "Add thumbnail image",
        },
        {
          id: "8c3bf665-7dcc-431e-a273-51fb17bf089d",
          title: "Analyze",
          description: "Analyze your users step by step usage ",
          url: "https://i.gifer.com/2IUB.gif",
          order: 2,
          tutorialName: "Add thumbnail image",
        },
      ],
    },
    {
      tutorialId: "df31e1bf-dddf-497a-ada4-e62bcf42cf76",
      title: "Video Tutorial",
      category: "",
      imageUrl: "https://i.imgur.com/N9iTayR.mp4",
      tutorialType: 200,
      productName: "Getir Food",
      companyName: "Getir",
      tutorialSteps: [],
    },
  ],
};

const CardsList = ({ onPress, setItem }) => {
  /*   const { data, error, loading, refetch } = useFetch(
    "https://localhost:7281/api/v1/Tutorial/GetTutorialsByProduct?productId=094d866c-604f-4c0f-b018-8068fa21e9ca&tutorialType=400&pageNumber=1&pageSize=10"
  ); */
  return (
    <View style={styles.container}>
      {data?.data.map((item, key) => (
        <Card
          noEdit
          onPress={onPress}
          data={item}
          key={key}
          setItem={setItem}
        />
      ))}
    </View>
  );
};

export default CardsList;
