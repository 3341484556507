import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Modal,
  Dimensions,
} from "react-native";
import { COLORS } from "../../constants/colors";

const CookieInfo = ({ visible, onAccept }) => {
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View style={styles.container}>
      <Modal animationType="fade" visible={visible} transparent={true}>
        <View style={styles.container}>
          <Text style={styles.text}>
            By continuing, you agree to the use of cookies.
          </Text>
          <View style={{ flexDirection: screenWidth > 800 && "row" }}>
            <TouchableOpacity style={styles.ghostButton} onPress={onAccept}>
              <Text style={styles.ghostButtonText}>Cookie info</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={onAccept}>
              <Text style={styles.buttonText}>Accept</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 16,
    backgroundColor: COLORS.PRIMARY,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  text: {
    color: COLORS.WHITE,
    marginRight: 10,
  },
  button: {
    backgroundColor: COLORS.SUCCESS,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
    marginLeft: 10,
  },
  buttonText: {
    color: "white",
  },
  ghostButton: {
    backgroundColor: "transparent",
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
  },
  ghostButtonText: {
    color: "#DADCEE",
    textDecorationLine: "underline",
  },
});

export default CookieInfo;
