import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { styles } from "./styles";

const CarouselPagination = ({ count = 5, active = 0 }) => {
  const dots = Array.from(Array(count).keys());

  return (
    <View style={styles.container}>
      {dots.map((dot) => (
        <View
          key={dot}
          style={[
            styles.dot,
            dot === active ? styles.activeDot : styles.inactiveDot,
          ]}
        />
      ))}
    </View>
  );
};

export default CarouselPagination;
