import React, {  } from "react";
import { View } from "react-native";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import BetaForm from "../../components/Auth/Beta";

const BetaScreen = () => {


  const { t } = useTranslation();
  return (
    <View style={styles.main}>
      <BetaForm />
    </View>
  );
};

export default BetaScreen;
