import React from "react";
import { StyleSheet, Text, View } from "react-native";

const Tooltip = ({ children, text }) => {
  return (
    <View style={styles.container}>
      {children}
      <View style={styles.tooltip}>
        <Text style={styles.tooltipText}>{text}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    left: 50,
    top: -40,
    zIndex: 999,
  },
  tooltip: {
    backgroundColor: "black",
    padding: 8,
    borderRadius: 4,
  },
  tooltipText: {
    color: "white",
  },
});

export default Tooltip;
