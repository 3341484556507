import { useRef, useState } from "react";
import { View, TextInput, Image, StyleSheet, Dimensions } from "react-native";
import { IconButton } from "./Button";

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  const clearSearch = () => {
    textInputRef.current.clear();
    setQuery("");
    onSearch("");
  };
  const handleSearch = () => {
    onSearch(query);
  };
  const textInputRef = useRef(null);
  return (
    <View style={styles.searchContainer}>
      <Image
        style={styles.searchIcon}
        source={require("../../assets/magnifying-glass.png")}
      />
      <TextInput
        ref={textInputRef}
        style={styles.searchInput}
        onChangeText={setQuery}
        onSubmitEditing={handleSearch}
        placeholder="Search for tutorials and help"
        value={query}
      />
      {query.length > 0 && (
        <View style={styles.clearButton}>
          <IconButton icon={"close"} onPress={clearSearch} color={"black"} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#EDEFF2",
    padding: 5,
    zIndex: 9999,
  },
  searchIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  searchInput: {
    flex: 1,
    padding: 5,
    color: "#4F5F80",
    width: "100%",
    minWidth: 300,
    outlineStyle: "none",
  },
  clearButton: {
    position: "absolute",
    right: 0,
    marginRight: 5,
  },
});

export default SearchBar;
