import { Dimensions, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import globalStyles from "../../constants/globalStyles";

const Terms = () => {
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View
      style={[
        styles.container,
        { paddingHorizontal: screenWidth > 800 ? 44 : 20 },
      ]}
    >
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Terms of Service"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Last updated: January 08, 2023"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "These Terms of Service are a contract between you and LearnBase. By using the LearnBase website (“Site”) and any services accessible from the LearnBase Site (collectively, the 'Services'), you are agreeing to be bound by the following Terms of Service. If a user violates any of the terms outlined below, we reserve the right to cancel accounts or bar access to accounts without notice. If you do not agree to these terms, please do not use our Services."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Your Account"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            " - You must be a human. Accounts registered by 'bots' or other automated methods are not permitted. \n"
          }
          {" - You must be 13 years of age or older.\n"}
          {
            " - You must provide a valid email address, along with any other information required by LearnBase during the registration process.\n"
          }
          {
            " - You are responsible for maintaining the security of your account and password. LearnBase will not be liable for any loss or damage from your failure to comply with this security obligation. Personally identifiable information submitted by you shall be subject to our Privacy Policy.\n"
          }

          {
            " - One person or legal entity may not maintain more than one free account.\n"
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Payments, Refunds, Upgrading and Downgrading"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "Unless otherwise arranged with an LearnBase representative, a valid credit card is required for paying accounts. Free accounts are not required to provide a credit card number. The LearnBase Service is billed in advance in accordance with our pricing schedule and all monthly and annual payments are nonrefundable. There will be no refunds or credits for partial periods of service, upgrade/downgrade refunds, or refunds for service periods unused with an open account. In order to treat everyone equally, no exceptions will be made. \n\n"
          }
          {
            "All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you shall be responsible for payment of all such taxes, levies, or duties, excluding only United States (federal or state) taxes. You agree to pay for any such taxes that might be applicable to your use of the LearnBase Site and payments made by you herein."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Violation of these Terms of Service"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "‍LearnBase reserves the right to investigate and prosecute violations of any of these Terms of Service to the fullest extent of the law. LearnBase may involve and cooperate with law enforcement authorities in prosecuting users who violate the Terms of Service. You acknowledge that LearnBase has no obligation to prescreen or monitor your access to or use of the LearnBase Site or any information, materials or other content provided or made available through the LearnBase Site, but has the right to do so. You hereby agree that LearnBase may, in the exercise of LearnBase' sole discretion, remove or delete any entries, information, materials or other content that violates these Terms of Service or that is otherwise objectionable."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Cancellation"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "You are solely responsible for properly canceling your Account. To do so, the account owner must send an email info@learnbase.co with 'Account Cancellation' in the subject line. Any cancellation of your Account will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. This information cannot be recovered from LearnBase once your account is canceled. Please be aware that LearnBase may for a time retain residual information in our backup and/or archival copies of our database. We will make reasonable commercial efforts to delete your information as soon as possible after you communicate that intention to us. \n\n"
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Termination"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "‍LearnBase reserves the right to terminate any free Accounts that do not have any activity for sixty (60) days or trial accounts without a payment method thirty (30) days after expiration. LearnBase, in its sole discretion, has the right to suspend or terminate your Account if you breach these Terms of Service. LearnBase may refuse to provide you any current or future use of the LearnBase Site, or any other LearnBase service. Any termination of your Account will result in the deactivation or deletion of your Account, denied access to your Account, and the forfeiture and relinquishment of all Content in your Account. This information cannot be recovered from LearnBase once your Account is terminated; however LearnBase may for a time retain residual information in our backup and/or archival copies of our database."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Modifications to the LearnBase Site and Prices"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "LearnBase reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the LearnBase Site (or any part thereof) with or without notice. \n"
          }
          {
            "Prices of all LearnBase plans, including but not limited to monthly subscription plan fees to the LearnBase Site, are subject to change. \n"
          }
          {
            "We reserve the right, in our sole discretion, to change, modify, add to, supplement or delete any portion of these Terms of Service at any time, effective with or without prior notice; provided, however, that we will use reasonable efforts to provide you with notification of any material changes (as determined in our sole discretion) by email. \n"
          }
          {
            "If any future changes to these Terms of Service are unacceptable to you or cause you to no longer be in compliance, you must immediately stop using the LearnBase Service. Your continued use of the LearnBase Service following any revision to this Agreement constitutes your complete acceptance of any and all such changes. Any new features that augment or enhance the current LearnBase Service, including the release of new tools and resources, will be subject to these Terms of Service. "
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Copyright and Content Ownership"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "We claim no intellectual property rights over the content submitted or created exclusively in your LearnBase account. Any content that is yours remains yours. \n"
          }
          {
            "These Terms do not grant us any licenses or rights to your content except for the limited rights needed for us to provide the Services to you. \n"
          }
          {
            "The look and feel of the LearnBase Site is © 2016 LearnBase. all rights reserved. You may not duplicate, copy, or reuse any portion of the code or visual design elements without express written permission."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Confidentiality"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "Definition of Confidential Information. “Confidential Information” means all information disclosed by a party (“Disclosing Party”) to the other party (“Receiving Party”), whether orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure.  Your Confidential Information includes your electronic data and information submitted by or for you to the Services; Confidential Information of LearnBase includes the Services, as well as business and marketing plans, technology and technical information, product plans and designs, and business processes disclosed by such party.  However, Confidential Information does not include any information that (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party, (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party, (iii) is received from a third party without breach of any obligation owed to the Disclosing Party, or (iv) was independently developed by the Receiving Party. \n"
          }
          {
            "Protection of Confidential Information.  The Receiving Party will use the same degree of care that it uses to protect the confidentiality of its own confidential information of like kind (but not less than reasonable care) (i) not to use any Confidential Information of the Disclosing Party for any purpose outside the scope of this Terms of Service, and (ii) except as otherwise authorized by the Disclosing Party in writing, to limit access to Confidential Information of the Disclosing Party to those of its employees and contractors who need that access for purposes consistent with this Terms of Service and who have signed confidentiality agreements with the Receiving Party containing protections no less stringent than those herein.  Neither party will disclose the terms of this Terms of Service or any Order Form to any third party other than its legal counsel and accountants without the other party’s prior written consent, provided that a party that makes any such disclosure to its legal counsel or accountants will remain responsible for such legal counsel’s or accountant’s compliance with this “Confidentiality” section. \n"
          }
          {
            "Compelled Disclosure.  The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent compelled by law to do so, provided the Receiving Party gives the Disclosing Party prior notice of the compelled disclosure (to the extent legally permitted) and reasonable assistance, at the Disclosing Party's cost, if the Disclosing Party wishes to contest the disclosure. If the Receiving Party is compelled by law to disclose the Disclosing Party’s Confidential Information as part of a civil proceeding to which the Disclosing Party is a party, and the Disclosing Party is not contesting the disclosure, the Disclosing Party will reimburse the Receiving Party for its reasonable cost of compiling and providing secure access to that Confidential Information."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Indemnification"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "‍You agree to indemnify and hold LearnBase, its parents, subsidiaries, affiliates, officers, partners and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Services, use of your account by any third party, the violation of the terms of use by you, or the infringement by you, or any third party using your account, of any intellectual property or other right of any person or entity."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Limitation of Liability"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL LEARNBASE OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, OR THIRD-PARTY PARTNERS OR SUPPLIERS, BE LIABLE TO YOU FOR ANY LOST PROFITS OR SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES THAT RESULT FROM OR RELATE TO THE SERVICES, INCLUDING YOUR USE THEREOF, OR ANY OTHER INTERACTIONS WITH LEARNBASE, EVEN IF LEARNBASE OR AN LEARNBASE AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL LEARNBASE’ OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, OR THIRD-PARTY PARTNERS OR SUPPLIERS’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT OR YOUR USE OF THE SITE OR SERVICES EXCEED EITHER THE AMOUNT OF FEES PAID BY YOU TO LEARNBASE FOR THE SERVICES IN THE SIX (6) MONTH PERIOD PRECEDING THE EVENTS THAT GIVE RISE TO THE APPLICABLE CLAIM OR FIFTY U.S. DOLLARS, WHICHEVER IS GREATER."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Disclaimer"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "THE SERVICES, LEARNBASE SITE AND ITS CONTENTS ARE PROVIDED 'AS IS' AND 'AS AVAILABLE' WITHOUT ANY WARRANTY OR REPRESENTATIONS OF ANY KIND, WHETHER EXPRESSED OR IMPLIED. LEARNBASE IS A DISTRIBUTOR AND NOT A PUBLISHER OF THE CONTENT SUPPLIED BY THIRD PARTIES; AS SUCH, LEARNBASE EXERCISES NO EDITORIAL CONTROL OVER SUCH CONTENT AND MAKES NO WARRANTY OR REPRESENTATION AS TO THE ACCURACY, RELIABILITY OR CURRENCY OF ANY INFORMATION, CONTENT, SERVICE OR MERCHANDISE PROVIDED THROUGH OR ACCESSIBLE VIA THE SITE. WITHOUT LIMITING THE FOREGOING, LEARNBASE SPECIFICALLY DISCLAIMS ALL WARRANTIES AND REPRESENTATIONS AS THE PUBLISHER OF ANY CONTENT TRANSMITTED ON OR IN CONNECTION WITH THE SITE OR ON SITES THAT MAY APPEAR AS LINKS ON THE SITE, OR THE SERVICES, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS. NO ORAL ADVICE OR WRITTEN INFORMATION GIVEN BY LEARNBASE NOR ANY OF ITS AFFILIATES, EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, OR THE LIKE SHALL CREATE A WARRANTY. PRICE AND AVAILABILITY INFORMATION IS SUBJECT TO CHANGE WITHOUT NOTICE."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingXLarge,
          }}
        >
          {"Publicity"}
        </Text>

        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "You grant LearnBase the right to use your company name and logo as a reference for marketing or promotional purposes on our website and in other public or private communications, subject to your standard trademark usage guidelines as provided to us from time-to-time. If you don’t want to be listed, you may send an email info@learnbase.co declining to be used as a reference."
          }
        </Text>
      </View>
    </View>
  );
};

export default Terms;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEF1F5",
    paddingVertical: 70,
  },
  featureContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    marginTop: 50,
    width: "100%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  heroMobileContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  leftContainer: {
    //maxWidth: 600,
    marginBottom: 20,
  },
  rightContainer: {
    justifyContent: "flex-start",
  },
  image: {
    height: 500,
    width: 500,
    resizeMode: "contain",
  },
});
