import React, { useRef, useState } from "react";
import { View, Dimensions, ScrollView } from "react-native";
import getUniqueID from "../../utils/generateId";
import { styles } from "./styles";
import Header from "../../components/Header";
import { Footer } from "../../components/Footer";
import Hero from "../../components/Hero";
import Features from "../../components/Sections/Features";
import Pricing from "../../components/Sections/Pricing";
import { useTranslation } from "react-i18next";
import Demo from "../../components/Demo";
import TutorialModal from "../../components/TutorialModal";

const MainScreen = () => {
  const { t } = useTranslation();

  const uniqueID = getUniqueID();

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("window").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  const [acceptedCookiePolicy, setAcceptedCookiePolicy] = useState(false);

  const handleAcceptCookiePolicy = () => {
    setAcceptedCookiePolicy(true);
  };

  const [tutorialModalVisible, setTutorialModalVisible] = useState(false);
  const [data, setData] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const toggleTutorialModal = () => {
    if (tutorialModalVisible) {
      setTutorialModalVisible(false);
      setModalVisible(true);
    } else if (!tutorialModalVisible) {
      setTutorialModalVisible(true);
      setModalVisible(false);
    }
  };
  return (
    <View style={[styles.main, { width: screenWidth }]}>
      {/*  {!acceptedCookiePolicy && (
        <CookieInfo onAccept={handleAcceptCookiePolicy} />
      )} */}
      <ScrollView>
        <Header />

        <Hero />
        {/* <SocialRef /> */}
        <Features
          type={"DEFAULT"}
          title={t("features.featureTitle1")}
          subtitle={t("features.featureSubtitle1")}
          featurePoint1={t("features.featurePoint1")}
          featurePoint2={t("features.featurePoint2")}
          featurePoint3={t("features.featurePoint3")}
          order={1}
          imageSource={require("../../../assets/images/feature1.png")}
        />
        <Features
          type={"REVERSE"}
          title={t("features.featureTitle2")}
          subtitle={t("features.featureSubtitle2")}
          featurePoint1={t("features.featurePoint4")}
          featurePoint2={t("features.featurePoint5")}
          featurePoint3={t("features.featurePoint6")}
          imageSource={require("../../../assets/images/feature2.png")}
          order={2}
        />

        <Pricing />

        <Footer />
      </ScrollView>
      {screenWidth > 800 && (
        <Demo
          toggleTutorialModal={toggleTutorialModal}
          setData={setData}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      )}
      {data && (
        <TutorialModal
          visible={tutorialModalVisible}
          setVisible={toggleTutorialModal}
          data={data}
        />
      )}
    </View>
  );
};

export default MainScreen;
