import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  View,
  Text,
   StyleSheet,
  Dimensions,
  Image,
} from "react-native";
import { COLORS } from "../../constants/colors";
import globalStyles from "../../constants/globalStyles";
import routes from "../../constants/routes";
import { HeroAction } from "../Button";

const BetaForm = () => {
  const navigation = useNavigation();
    const { t } = useTranslation();

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View style={styles.container}>
            {/* Logo */}
            <Image
          style={styles.image}
          source={require("../../../assets/logo.png")}
        />
     
            <Text style={[globalStyles.bodyLargeSemiBold, {marginTop:20}]}>
            Thanks for joining us!
          </Text>
          <Text style={[globalStyles.bodyLargeRegular, {marginTop:20}]}>
            We'll let you know as soon as you are eligible for closed beta. Check your mail for further instructions. 
          </Text>
          <HeroAction
        title={t("hero.goBack")}
        onPress={() => navigation.navigate(routes.MAIN)}
        style={{
          backgroundColor: COLORS.PRIMARY,
          borderRadius: 8,
          height: 50,
          maxWidth: 350,
          width: "100%",
          alignSelf: "center",
          borderWidth: 1,
          borderColor: COLORS.PRIMARY,
          marginVertical: 20,
        }}
        textColor={COLORS.WHITE}
      />
        </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height:300,
    width:"95%",
    maxWidth:400,
    padding: 32,
    borderRadius:8,
    backgroundColor: COLORS.WHITE,    
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignItems: "center",
    alignSelf: "center",
    flexDirection: "column",
  },
  image: {
    height: 30,
    marginRight:15,
    width: 145,
    resizeMode: "contain",
  },
  text: {
    color: COLORS.TEXT_PRIMARY,
    marginTop:40,
    textAlign:"center",
    fontSize:20
  },
  
  ghostButton: {
    backgroundColor: "transparent",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 4,
  },
  ghostButtonText: {
    color: COLORS.TEXT_PRIMARY,
    textDecorationLine: "underline",
  },input: {
    height: 50,
    backgroundColor: COLORS.TEXT_LIGHT,
    maxWidth: 350,
    width: "100%",
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: COLORS.BORDER,
    padding: 20,
    color: COLORS.TEXT_DARK,
    marginTop: 20,
  },
});

export default BetaForm;
