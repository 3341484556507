import { Dimensions, Image, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { COLORS } from "../../constants/colors";
import globalStyles from "../../constants/globalStyles";
import { HeroAction } from "../Button";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import ROUTES from "../../constants/routes";
const Hero = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("window").width
  );
  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View style={styles.container}>
      <View
        style={
          screenWidth > 800 ? styles.heroContainer : styles.heroMobileContainer
        }
      >
        <View
          style={{
            ...styles.leftContainer,
            width: screenWidth > 800 ? screenWidth * 0.4 : screenWidth * 0.9,
            paddingRight: 20,
          }}
        >
          <Text
            style={[
              screenWidth > 800
                ? globalStyles.headingXLarge
                : globalStyles.headingLarge,
              {
                textAlign: screenWidth > 800 ? "left" : "center",
                fontSize: screenWidth > 800 ? 48 : 28,
              },
            ]}
          >
            {t("hero.title")}
          </Text>
          <Text
            style={[
              screenWidth > 800
                ? globalStyles.headingMedium
                : globalStyles.bodyLargeSemiBold,
              {
                textAlign: screenWidth > 800 ? "left" : "center",
                marginTop: 25,
              },
            ]}
          >
            {t("hero.subtitle")}
          </Text>
          <View
            style={{
              alignSelf: screenWidth > 800 ? "left" : "center",
              alignItems: screenWidth > 800 ? "left" : "center",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Icon
                name={"checkmark-circle"}
                size={20}
                color={COLORS.PRIMARY}
              />
              <Text
                style={{
                  ...globalStyles.bodySmallSemiBold,
                  textAlign: screenWidth > 800 ? "left" : "center",
                  marginLeft: 10,
                }}
              >
                {t("hero.feature1").toUpperCase()}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Icon
                name={"checkmark-circle"}
                size={20}
                color={COLORS.PRIMARY}
              />
              <Text
                style={{
                  ...globalStyles.bodySmallSemiBold,
                  textAlign: screenWidth > 800 ? "left" : "center",
                  marginLeft: 10,
                }}
              >
                {t("hero.feature2").toUpperCase()}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Icon
                name={"checkmark-circle"}
                size={20}
                color={COLORS.PRIMARY}
              />
              <Text
                style={{
                  ...globalStyles.bodySmallSemiBold,
                  textAlign: screenWidth > 800 ? "left" : "center",
                  marginLeft: 10,
                }}
              >
                {t("hero.feature3").toUpperCase()}
              </Text>
            </View>
          </View>
          {/* Action Button */}
          <View style={{ marginTop: 40 }}>
            <HeroAction
              title={t("hero.actionItem")}
              onPress={() => navigation.navigate(ROUTES.PRICING)}
              backgroundColor={COLORS.SECONDARY}
              textColor={COLORS.TEXT_SECONDARY}
              style={{
                height: 56,
                width: 200,
                backgroundColor: COLORS.PRIMARY700,
                alignSelf: screenWidth > 800 ? "baseline" : "center",
                marginBottom: 10,
              }}
            />
            <Text
              style={[
                screenWidth > 800
                  ? globalStyles.bodyLargeSemiBold
                  : globalStyles.bodyMediumSemiBold,
                {
                  textAlign: screenWidth > 800 ? "left" : "center",
                  color: COLORS.PRIMARY700,
                  marginBottom: screenWidth <= 800 && 20,
                },
              ]}
            >
              {t("hero.freeTrial")}
            </Text>
          </View>
        </View>
        <Image
          style={{
            ...styles.image,
            borderRadius: 15,
            resizeMode: "contain",
            alignSelf: screenWidth > 800 ? "baseline" : "center",
            width: screenWidth > 800 ? screenWidth * 0.5 : screenWidth * 0.9,
            height:
              screenWidth > 800
                ? (screenWidth * 0.5 * 9) / 16
                : (screenWidth * 0.9 * 9) / 16,
            aspectRatio: 16 / 9,
            maxHeight: screenWidth <= 800 && 400,
          }}
          source={require("../../../assets/images/hero.png")}
        />
        {/* Visual */}
      </View>
    </View>
  );
};

export default Hero;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEF1F5",
    padding: 44,
    paddingTop: 100,
    paddingBottom: 200,
    alignItems: "center",
    //height:Dimensions.get("screen").height - 80
  },

  heroContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1400,
    width: "100%",
    alignSelf: "center",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heroMobileContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  leftContainer: {
    maxWidth: 600,
  },
  rightContainer: {
    /*     justifyContent: "flex-start",
    flex: 1, */
  },
  image: {
    height: 327 * 2,
    width: 581 * 2,
  },
  input: {
    height: 56,
    backgroundColor: COLORS.TEXT_LIGHT,
    maxWidth: 350,
    width: "100%",
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: COLORS.BORDER,
    padding: 20,
    color: COLORS.TEXT_DARK,
    marginBottom: 12,
  },
  editButton: {
    height: 28,
    width: 28,
    borderRadius: 6,
    backgroundColor: "transparent",
    padding: 10,
  },
});
