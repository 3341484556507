import { View, Text } from "react-native";
import React from "react";
import { CustomButton } from "../Button";
import { styles } from "./styles";

const DashboardTitle = ({ onPress }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>Your tutorial center</Text>
      <CustomButton
        title={"Add Tutorial"}
        leftIcon={"add"}
        styleType={"primary"}
        rounded
        onPress={() => onPress(true)}
        containerStyle={styles.button}
      />
    </View>
  );
};

export default DashboardTitle;
