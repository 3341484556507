import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const styles = StyleSheet.create({
  body: {
    backgroundColor: "#ffffff",
    flex: 1,
    paddingHorizontal: 40,
    paddingTop: 20,
    justifyContent: "flex-start",
    alignItems: "baseline",
  },
  panelContent: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
  },
  sidePanel: {
    height: "95%",
    width: 260,
    backgroundColor: "#3E4BBC",
    marginRight: 40,
    borderRadius: 20,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "flex-start",
  },
  upperPanel: {
    height: 100,
    width: "100%",
    backgroundColor: "#3E4BBC",
    borderRadius: 20,
    marginBottom: 40,
  },

  left: {
    width: "50%",
  },
  right: { marginLeft: 30 },
  previewContainer: {
    width: 280,
    backgroundColor: "white",
    marginTop: 20,
    borderRadius: 8,
    shadowColor: COLORS.BLACK,
    shadowOffset: { width: 2, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    paddingHorizontal: 24,
    paddingVertical: 10,
  },
  previewTitle: {
    fontWeight: "bold",
    fontSize: 18,
  },
  preview: {
    marginTop: 20,
    borderRadius: 8,
    width: "100%",
    backgroundColor: "#252525",
    height: 360,
  },
  previewIcon: {
    marginTop: 8,
    borderRadius: 100,
    width: 80,
    height: 80,
    alignSelf: "flex-end",
    backgroundColor: "#252525",
  },
  learningCenter: {
    backgroundColor: "white",
    height: 160,
    width: "100%",
    borderRadius: 8,
    shadowColor: COLORS.BLACK,
    shadowOffset: { width: 2, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    marginBottom: 20,
    paddingHorizontal: 24,
    paddingVertical: 10,
  },
  learningCenterTitle: {
    fontWeight: "bold",
    fontSize: 18,
  },
  section: {
    backgroundColor: "white",
    minHeight: 110,
    width: "100%",
    borderRadius: 8,
    shadowColor: COLORS.BLACK,
    shadowOffset: { width: 2, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    marginBottom: 20,
    paddingHorizontal: 24,
    paddingVertical: 10,
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "baseline",
    marginTop: 20,
  },
  sectionTitleContainer: {
    marginBottom: 20,
    paddingHorizontal: 10,
    paddingVertical: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sectionTitle: { fontWeight: "bold", fontSize: 18 },
  sectionIcons: {
    flexDirection: "row",
    alignItems: "center",
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#6C63FF",
    padding: 20,
  },
  addButton: {
    backgroundColor: "#6C63FF",
    padding: 10,
    borderRadius: 50,
  },
  title: {
    fontSize: 24,
    color: "#fff",
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#f8f8f8",
    padding: 16,
    marginBottom: 8,
    borderRadius: 5,
  },
  itemText: {
    fontSize: 18,
  },
  handle: {
    marginLeft: "auto",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 5,
    elevation: 5,
  },
  input: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  sectionContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: [{ translateX: -100 }, { translateY: -100 }],
    backgroundColor: "#f8f8f8",
    padding: 16,
    borderRadius: 5,
    elevation: 5,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 8,
  },
  sectionList: {
    maxHeight: 150,
  },
  sectionItem: {
    fontSize: 16,
    marginBottom: 8,
  },
});
