import React, { useRef } from "react";
import { View } from "react-native";
import { styles } from "./styles";
import Header from "../../components/Header";
import { Footer } from "../../components/Footer";
import Why from "../../components/Footer/Why";

const WhyScreen = () => {
  return (
    <View style={styles.main}>
      <Header />

      <Why />

      <Footer />
    </View>
  );
};

export default WhyScreen;
