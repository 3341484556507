import React, { useState } from "react";
import { View, Text, Switch, Modal, TextInput, Button } from "react-native";
import { CustomButton, IconButton } from "../../components/Button";
import CardsList from "../../components/CardList";
import SortableList from "../../components/Section";
import DashboardTitle from "../../components/Title";
import AddTutorialModal from "../../components/Tutorial";
import { COLORS } from "../../constants/colors";
import { styles } from "./styles";

const DashboardScreen = ({ navigation }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <View style={styles.body}>
      <View style={styles.panelContent}>
        <View style={styles.sidePanel}>{/* Side Panel */}</View>
        <View style={styles.contentContainer}>
          <View style={styles.upperPanel}>{/* Top content */}</View>
          <DashboardTitle onPress={setModalVisible} />
          <CardsList onPress={setModalVisible}/>
          <AddTutorialModal
            visible={modalVisible}
            setVisible={setModalVisible}
          />
          {/* <View style={styles.content}> */}
          {/* <View style={styles.left}>
              <View style={styles.section}>
                <SortableList />
              </View>
            </View> */}
          {/* </View> */}
        </View>
      </View>
    </View>
  );
};

export default DashboardScreen;
