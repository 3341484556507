import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Image,
  Linking,
} from "react-native";
import { COLORS } from "../../constants/colors";
import globalStyles from "../../constants/globalStyles";
import ROUTES from "../../constants/routes";
import { IconButton, TextButton } from "../Button";
import CookieInfo from "../Cookie";
export const Footer = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("window").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View
      style={[
        styles.footer,
        {
          flexDirection: screenWidth > 800 ? "column" : "column",
          paddingVertical: 50,
          paddingHorizontal: screenWidth > 800 && 100,
          alignSelf: "center",
          width: "100%",
          maxWidth: 1400,
        },
      ]}
    >
      <View
        style={{
          flexDirection: "column",
          width: 300,
          margin: 16,
          flex: 1,
          alignItems: screenWidth <= 800 && "center",
          alignSelf: screenWidth <= 800 && "center",
        }}
      >
        <Image
          style={styles.image}
          source={require("../../../assets/logo.png")}
        />
      </View>
      <View
        style={{
          alignItems: screenWidth <= 800 && "center",
          flexDirection: screenWidth > 800 ? "row" : "column",
          padding: 16,
          justifyContent: "space-between",
          width: "100%",
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        <View
          style={{
            marginBottom: 16,
            alignItems: screenWidth <= 800 && "center",
          }}
        >
          <Text
            style={[
              globalStyles.bodyLargeBold,
              { fontWeight: "bold", marginBottom: 8, color: "#6E6E6E" },
            ]}
          >
            {t("footer.product")}
          </Text>
          <TextButton
            text={t("footer.why")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.WHY)}
          />
          <TextButton
            text={t("footer.how")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.HOW)}
          />
          {/*           <TextButton
            text={t("footer.integrations")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={productRef}
          /> */}
          <TextButton
            text={t("footer.security")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.COOKIE)}
          />
          <TextButton
            text={t("footer.pricing")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.PRICING)}
          />
        </View>
        {/* <View
          style={{
            marginBottom: 16,
            alignItems: screenWidth <= 800 && "center",
          }}
        >
          <Text
            style={[
              globalStyles.bodyLargeBold,
              { fontWeight: "bold", marginBottom: 8, color: "#6E6E6E" },
            ]}
          >
            {t("footer.company")}
          </Text>
          <TextButton
            text={t("footer.aboutUs")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={pricingRef}
          />
          <TextButton
            text={t("footer.career")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={productRef}
          />
        </View> */}
        <View
          style={{
            marginBottom: 16,
            alignItems: screenWidth <= 800 && "center",
          }}
        >
          <Text
            style={[
              globalStyles.bodyLargeBold,
              { fontWeight: "bold", marginBottom: 8, color: "#6E6E6E" },
            ]}
          >
            {t("footer.support")}
          </Text>
          <TextButton
            text={t("footer.demo")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.PRICING)}
          />
          <TextButton
            text={t("footer.freeTrial")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.PRICING)}
          />
          <TextButton
            text={t("footer.faq")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.FAQ)}
          />
          {/*  <TextButton
            text={t("footer.docs")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={aboutRef}
          /> */}
          <TextButton
            text={t("footer.contact")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.CONTACT)}
          />
          <TextButton
            text={t("footer.policy")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.PRIVACY)}
          />
          <TextButton
            text={t("footer.terms")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                marginBottom: 8,
                textAlign: "left",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.TERMS)}
          />
        </View>

        <View
          style={{
            flexDirection: screenWidth > 800 ? "column" : "column",
            alignItems: "center",
            marginLeft: 10,
          }}
        >
          {/*         <Text
            style={[
              globalStyles.bodyLargeBold,
              {
                fontWeight: "bold",
                marginBottom: 8,
                color: "#6E6E6E",
                alignSelf: screenWidth > 800 ? "baseline" : "center",
                textAlign: "center",
              },
            ]}
          >
            {t("footer.followUs")}
          </Text>
          <View
            style={{
              flexDirection: screenWidth > 800 ? "row" : "row",
              alignSelf: "baseline",
            }}
          >
            <IconButton
              icon="logo-twitter"
              color={COLORS.BLACK}
              size={36}
              onPress={() => {
                Linking.openURL("https://twitter.com");
              }}
            />
            <IconButton
              icon="logo-instagram"
              color={COLORS.BLACK}
              size={36}
              onPress={() => {
                Linking.openURL("https://instagram.com");
              }}
            />
            <IconButton
              icon="logo-linkedin"
              color={COLORS.BLACK}
              size={36}
              onPress={() => {
                Linking.openURL("https://linkedin.com");
              }}
            />
          </View> */}
        </View>
      </View>
      <View
        style={{
          height: 64,
          width: "100%",
          justifyContent: "space-between",
          flexDirection: screenWidth > 800 ? "row" : "column",
          position: "absolute",
          bottom: 0,
          left: 0,
          alignItems: "center",
          paddingHorizontal: 30,
          paddingVertical: 10,
        }}
      >
        <Text style={[globalStyles.bodyMediumRegular, { color: "#6E6E6E" }]}>
          {t("footer.copyright")}
        </Text>
        <View
          style={{
            alignItems: "center",
            flexDirection: screenWidth > 800 ? "row" : "column",
          }}
        >
          {screenWidth <= 800 && <View style={{ height: 15 }} />}
          <TextButton
            text={t("footer.security")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                textAlign: "left",
                color: "#A1A1A1",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.COOKIE)}
          />
          {screenWidth <= 800 && <View style={{ height: 15 }} />}
          <TextButton
            text={t("footer.policy")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                textAlign: "left",
                color: "#A1A1A1",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.PRIVACY)}
          />
          {screenWidth <= 800 && <View style={{ height: 15 }} />}
          <TextButton
            text={t("footer.terms")}
            textStyle={[
              globalStyles.bodyMediumRegular,
              {
                textAlign: "left",
                color: "#A1A1A1",
              },
            ]}
            onPress={() => navigation.navigate(ROUTES.TERMS)}
          />
          {screenWidth <= 800 && <View style={{ height: 30 }} />}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  image: {
    height: 50,
    width: 145,
    resizeMode: "contain",
  },
});
