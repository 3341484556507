import {
  Dimensions,
  Image,
  Modal,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import { COLORS } from "../../constants/colors";
import { AuthButtons, IconButton, TextButton } from "../Button";
import NavBar from "../NavBar";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";
import ROUTES from "../../constants/routes";

const Header = () => {
  const navigation = useNavigation();
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("window").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });
  const [menuVisible, setMenuVisible] = useState(false);

  const onLoginPressed = () => {
    return setMenuVisible(false), navigation.navigate(ROUTES.LOGIN);
  };
  const onSignUpPressed = () => {
    return setMenuVisible(false), navigation.navigate(ROUTES.PRICING);
  };
  const Menu = () => {
    const { t } = useTranslation();
    return (
      <Modal animationType="none" transparent={true} visible={menuVisible}>
        <View style={styles.modalContainer}>
          <View
            style={{
              position: "absolute",
              top: 20,
              right: 10,
              zIndex: 999,
            }}
          >
            <IconButton
              icon="ios-close"
              color={COLORS.BLACK_SOFT}
              size={30}
              onPress={() => {
                setMenuVisible(false);
              }}
            />
          </View>
          <View style={{ height: 50 }}></View>
          <View
            style={{
              alignSelf: "center",
              alignItems: "center",
              height: "90%",
            }}
          >
            <TextButton
              text={t("footer.why")}
              onPress={() => navigation.navigate(ROUTES.WHY)}
              textStyle={{ color: COLORS.TEXT_PRIMARY }}
            />
            <View style={{ height: 50 }}></View>
            <TextButton
              text={t("footer.how")}
              onPress={() => navigation.navigate(ROUTES.HOW)}
              textStyle={{ color: COLORS.TEXT_PRIMARY }}
            />
            <View style={{ height: 50 }}></View>
            {/*             <TextButton
              text={t("header.customer")}
              onPress={productRef}
              textStyle={{ color: COLORS.TEXT_PRIMARY }}
            />
            <View style={{ height: 50 }}></View> */}
            <TextButton
              text={t("header.pricing")}
              onPress={() => navigation.navigate(ROUTES.PRICING)}
              textStyle={{ color: COLORS.TEXT_PRIMARY }}
            />
            <View style={{ height: 50 }}></View>
            {/*  <TextButton
              text={t("header.aboutUs")}
              onPress={aboutRef}
              textStyle={{ color: COLORS.TEXT_PRIMARY }}
            />
            <View style={{ height: 50 }}></View> */}
            <AuthButtons
              horizontal
              loginPressed={onLoginPressed}
              signUpPressed={onSignUpPressed}
            />
          </View>
        </View>
      </Modal>
    );
  };
  return (
    <View style={styles.header}>
      <View style={styles.itemsContainer}>
        <View
          style={{
            height: "100%",
            width: 145,
          }}
        >
          <TouchableOpacity
            style={{
              height: "100%",
              width: 145,
            }}
            onPress={() => navigation.navigate(ROUTES.MAIN)}
          >
            <Image
              style={styles.image}
              source={require("../../../assets/logo.png")}
            />
          </TouchableOpacity>
        </View>
        {screenWidth > 800 ? (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <NavBar />
            <AuthButtons
              loginPressed={onLoginPressed}
              signUpPressed={onSignUpPressed}
            />
          </View>
        ) : (
          <IconButton
            icon="ios-menu"
            size={32}
            color={COLORS.BLACK_SOFT}
            onPress={() => setMenuVisible(true)}
          />
        )}
        <Menu />
      </View>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  header: {
    backgroundColor: COLORS.WHITE,
    height: 80,
    zIndex: 999,
  },
  itemsContainer: {
    width: "100%",
    flexDirection: "row",
    paddingHorizontal: 20,
    height: 80,
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
  },
  image: {
    height: "100%",
    width: 145,
    marginLeft: 10,
    resizeMode: "contain",
  },
  modalContainer: {
    height: "100%",
    justifyContent: "space-between",
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: COLORS.WHITE,
    padding: 20,
    width: "100%",
  },
});
