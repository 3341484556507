import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const styles = StyleSheet.create({
  reactionContainer: {
    flexDirection: "row",
    borderWidth: 0,
    borderColor: "#8B9AB7",
    borderWidth: 0.5,
    borderRadius: 30,
    height: 30,
    paddingHorizontal: 5,
    marginRight: 5,
  },
  reactionCount: {
    fontSize: 16,
    fontWeight: "300",
    textAlign: "center",
    alignSelf: "center",
    marginLeft: 3,
  },
  reactionEmoji: {
    height: 20,
    width: 20,
    alignSelf: "center",
  },
  container: {
    flexDirection: "row",
    borderRadius: 8,
    overflow: "hidden",
    alignSelf: "center",
    width: 180,
    marginVertical: 35,
  },
  button: {
    flex: 1,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#D4D4D4",
    alignItems: "center",
    justifyContent: "center",
    height: 50,
  },
  buttonText: {
    fontSize: 16,
    color: COLORS.TEXT_PRIMARY,
  },
  selectedButton: {
    backgroundColor: "#ECEDF8",
  },
  selectedButtonText: {
    color: "#3E4BBC",
  },
});
