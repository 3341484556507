import React, { useRef } from "react";
import { View, ScrollView } from "react-native";
import { styles } from "./styles";
import Header from "../../components/Header";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";
import Terms from "../../components/Footer/Terms";

const TermsScreen = () => {
  return (
    <View style={styles.main}>
      <Header />

      <Terms />

      <Footer />
    </View>
  );
};

export default TermsScreen;
