import { Dimensions, StyleSheet, Text, TextInput, View } from "react-native";
import React, { useState } from "react";
import { COLORS } from "../../constants/colors";
import globalStyles from "../../constants/globalStyles";
import { ActionButton } from "../Button";

const Contact = () => {
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (!name || !email || !message) {
      alert("Please fill in all fields.");
      return;
    }
    alert("Thank you for your message!");
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <View
      style={[
        styles.container,
        { paddingHorizontal: screenWidth > 800 ? 44 : 20 },
      ]}
    >
      <View style={styles.contactContainer}>
        <Text
          style={{
            ...globalStyles.headingLarge,
            marginVertical: 20,
            fontWeight: "600",
            alignSelf: "baseline",
          }}
        >
          {"Contact Us"}
        </Text>
        <TextInput
          style={styles.input}
          placeholder="Enter your name"
          value={name}
          onChangeText={setName}
        />
        <TextInput
          style={styles.input}
          placeholder="Enter your email address"
          value={email}
          onChangeText={setEmail}
          keyboardType="email-address"
        />
        <TextInput
          style={styles.input}
          placeholder="Enter your message"
          value={message}
          onChangeText={setMessage}
          multiline={true}
          numberOfLines={5}
        />
        <View style={{ height: 20 }}></View>
        <ActionButton title={"Submit"} onPress={handleSubmit} />
      </View>
    </View>
  );
};

export default Contact;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEF1F5",
    paddingVertical: 70,
  },
  featureContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    marginTop: 50,
    width: "100%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  heroMobileContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  leftContainer: {
    //maxWidth: 600,
    marginBottom: 20,
  },
  rightContainer: {
    justifyContent: "flex-start",
  },
  image: {
    height: 500,
    width: 500,
    resizeMode: "contain",
  },
  contactContainer: {
    width: "90%",
    height: "90%",
    maxWidth: 400,
    maxHeight: 500,
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
    padding: 24,
    alignItems: "center",
  },
  input: {
    backgroundColor: COLORS.TEXT_LIGHT,
    width: "100%",
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: COLORS.BORDER,
    padding: 20,
    color: COLORS.TEXT_DARK,
    marginTop: 20,
  },
});
