import React, { useState } from "react";
import { View, Modal, Text } from "react-native";
import { CustomButton } from "../Button";
import CardsList from "../CardList";
import { ModalHeader } from "../Modal";
import { styles } from "./styles";

const LearningCenter = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const handlePress = () => {
    setModalVisible(true);
  };
  const handleSave = (section) => {
    null;
  };
  const onClose = () => {
    setModalVisible(false);
  };
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        position: "absolute",
        right: 25,
        bottom: 25,
      }}
    >
      <Text style={styles.text}>{"Try me ->"}</Text>
      <CustomButton
        leftIcon={"md-help-circle"}
        iconSize={64}
        iconColor={"#303030"}
        onPress={handlePress}
        containerStyle={{ backgroundColor: "transparent" }}
      />
      <Modal
        visible={modalVisible}
        animationType="none"
        transparent={true}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <ModalHeader onClose={onClose} />
            <View style={styles.bodyContainer}>
              <CardsList />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default LearningCenter;
