export default {
  //Auth
  LOGIN: "Login",
  SIGNUP: "Signup",
  FORGOTPASSWORD: "Forgot Password",

  //Home Stack
  MAIN: "LearnBase",
  PRICING: "Pricing",
  DEMO: "Demo",
  BETA: "Beta",

  //App Stack
  DASHBOARD: "Dashboard",

  //Footer
  TERMS: "Terms",
  PRIVACY: "Privacy",
  COOKIE: "Cookie",
  FAQ: "FAQ",
  CONTACT: "Contact",
  WHY: "Why",
  HOW: "How",
};
