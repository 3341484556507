import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, Dimensions, Image, TextInput } from "react-native";
import { COLORS } from "../../constants/colors";
import { HeroAction } from "../Button";
import ROUTES from "../../constants/routes";
import HelperText from "../HelperText";
import { useFetch } from "../../services/hooks";

const SignInForm = ({ selectedPrice }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const [mail, setMail] = useState("");
  const [helper, setHelper] = useState(false);

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const onSignInPressed = async () => {
    if (emailRegex.test(mail)) {
      setUrl("https://ekipwebapi.azurewebsites.net/api/Account/Register");
      setBody({
        fullName: `${"*  " + selectedPrice}`,
        email: mail.toLocaleLowerCase(),
        password: "Lead123*",
        confirmPassword: "Lead123*",
        role: "Student",
      });
      setHelper(false);
      navigation.navigate(ROUTES.BETA);
    } else {
      setHelper(true);
    }
  };
  const { data, error, loading, setBody, setUrl } = useFetch(
    null,
    "POST",
    null
  );

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require("../../../assets/logo.png")}
      />
      <TextInput
        style={styles.input}
        placeholder={"Work mail"}
        maxLength={100}
        onSubmitEditing={onSignInPressed}
        onChangeText={(text) => setMail(text)}
        value={mail}
      />
      {helper && (
        <HelperText type="error" text={"Please enter a valid mail address"} />
      )}
      <HeroAction
        title={"Join Beta"}
        onPress={() => onSignInPressed()}
        style={{
          backgroundColor: COLORS.PRIMARY,
          borderRadius: 8,
          height: 50,
          maxWidth: 350,
          width: "100%",
          alignSelf: "center",
          borderWidth: 1,
          borderColor: COLORS.PRIMARY,
          marginVertical: 20,
        }}
        textColor={COLORS.WHITE}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 300,
    width: "95%",
    maxWidth: 400,
    padding: 32,
    borderRadius: 8,
    backgroundColor: COLORS.WHITE,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  image: {
    height: 30,
    marginRight: 15,
    width: 145,
    resizeMode: "contain",
  },
  text: {
    color: COLORS.TEXT_PRIMARY,
  },
  ghostButton: {
    backgroundColor: "transparent",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 4,
  },
  ghostButtonText: {
    color: COLORS.TEXT_PRIMARY,
    textDecorationLine: "underline",
  },
  input: {
    height: 50,
    backgroundColor: COLORS.TEXT_LIGHT,
    maxWidth: 350,
    width: "100%",
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: COLORS.BORDER,
    padding: 20,
    color: COLORS.TEXT_DARK,
    marginTop: 20,
  },
});

export default SignInForm;
{
  /* <View style={{flexDirection:"row", alignItems:"center"}}>
            <Text style={styles.text}>
            Don't have an account? 
          </Text>
          <TouchableOpacity style={styles.ghostButton} onPress={()=>null}>
              <Text style={styles.ghostButtonText}>Get one</Text>
            </TouchableOpacity>
            </View>

            <Text style={[styles.text, {marginTop:20}]}>OR</Text>

<HeroAction
source={require("../../../assets/images/google.png")}
imageStyle={{height:24,width:24, alignSelf:"center", marginRight:10}}
        title={"Continue with Google"}
        onPress={() => null}
        style={{
          backgroundColor: COLORS.WHITE,
          borderRadius: 8,
          height: 50,
          maxWidth: 350,
          width: "100%",
          alignSelf: "center",
          borderWidth: 1,
          borderColor: COLORS.BORDER,
          marginVertical: 20,
          flexDirection:"row",
          alignItems:"center"
        }}
        textColor={COLORS.TEXT_DARK}
      /> */
}
