import { Dimensions, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import globalStyles from "../../constants/globalStyles";

const Why = () => {
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View
      style={[
        styles.container,
        { paddingHorizontal: screenWidth > 800 ? 44 : 20 },
      ]}
    >
      <View style={{ marginBottom: 20 }}>
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Why LearnBase?"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "LearnBase is the perfect solution for companies looking to provide their website users with a seamless and intuitive learning experience. With LearnBase, you can quickly and easily integrate the learning center into your website, making it accessible to your users with just a click of a button."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
            fontWeight: "600",
          }}
        >
          {
            "One of the biggest benefits of LearnBase is that after the initial integration, no ongoing development effort is required. This means that anyone on your team, from operations to marketing, can manage and add tutorials and links without needing to involve your web developer. This not only saves time and money, but also allows for more flexibility in managing your website's learning resources."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            "Here are just a few other reasons why LearnBase is the right choice for your website's learning center and knowledge hub:"
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 15,
          }}
        >
          {
            " - Easy Integration: Our product is designed to be easily integrated into your website using our software development kit (SDK). Once integrated, you can manage and add tutorials without any additional development effort. \n\n - Customizable Tutorials and Links: With LearnBase, you can add tutorials and URL links that are tailored specifically to your website and its features. This allows you to provide your users with targeted, relevant information that will help them use your website more effectively and find important resources easily. \n\n - User-Friendly Interface: Our product is designed with user experience in mind, providing a simple and intuitive interface for managing your tutorials, links, and learning center.\n\n - Customer Support: We take customer support seriously and are committed to providing our customers with the help they need. Our support team is available via email and phone, and we also offer a help center with resources and answers to common questions.\n\n - Data Security: We understand the importance of keeping your data secure and private. Our product uses industry-standard encryption and security measures to protect your information and keep it safe."
          }
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {
            "So if you're looking to provide your website users with the resources and knowledge they need to make the most of your website, without the hassle of ongoing development effort, LearnBase is the solution for you."
          }
        </Text>
      </View>
    </View>
  );
};

export default Why;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEF1F5",
    paddingVertical: 70,
  },
});
