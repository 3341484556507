import { Dimensions, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import globalStyles from "../../constants/globalStyles";
import { useTranslation } from "react-i18next";

const Cookie = () => {
  const { t } = useTranslation();

  const [period, setPeriod] = useState("month");

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("screen").width
  );

  Dimensions.addEventListener("change", ({ window }) => {
    setScreenWidth(window.width);
  });

  return (
    <View
      style={[
        styles.container,
        { paddingHorizontal: screenWidth > 800 ? 44 : 20 },
      ]}
    >
      <View style={{ marginBottom: 20 }}>
        {/* Title */}
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Cookie Policy"}
        </Text>
        {/* Subtitle */}
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
            fontWeight: "600",
          }}
        >
          {"Last updated: February 24, 2023"}
        </Text>
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "This cookies policy (the “Cookies Policy”) describes the different types of cookies and similar technologies that LearnBase may be apply on our Websites, such as www.learnbase.co and services such as dashboard.learnbase.co of LearnBase, Inc. (“LearnBase”). \nWe may change this Cookies Policy at any time. The “Effective Date” at the top of this page indicates when this Cookies Policy was last revised. Any changes in this Cookies Policy will become effective when published the revised Cookies Policy on the Website. \nIf you have any further queries, please contact us by email at [info@learnbase.co](mailto:info@learnbase.co)."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        {/* Title */}
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"What is a Cookie?"}
        </Text>
        {/* Subtitle */}
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "Cookies are a standard feature of websites and web applications that allow us to store small amounts of data on your computer about your visit to the Website or Service. LearnBase use cookies to personalize your experience on the LearnBase’ websites (such as dynamically generating content on webpages specifically designed for you), to allow us to statistically monitor how you are using the LearnBase’ Service so that we can improve our offerings, and to target certain advertisements to your browser which may be of interest to you or to determine the popularity of certain content. By using cookies, we are able to improve the LearnBase’ Service and measure the effectiveness of our advertising and marketing campaigns."
          }
          {
            "\n\nYou can choose whether to accept cookies by changing the settings on your browser. However, if you disable this function, your experience on the Website or Service may be diminished and some features may not work as intended."
          }
          {
            "\n\nPlease be aware that this cookie policy does not govern the use of third-party websites or services or providers of third-party services."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        {/* Title */}
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"What Cookies Does LearnBase Use?"}
        </Text>
        {/* Subtitle */}
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "Below we list examples of the different types of cookies that may be used on the Site. Our Privacy Policy (https://www.learnbase.co/privacy) applies and compliments this Cookies Policy."
          }
          {
            "The LearnBase’ Website and Service use both 1st party cookies (which are set by the LearnBase’ Service) and 3rd party cookies (which are set by a server located outside the domain of the LearnBase’ Website or Services)."
          }
          {
            "- Amplitude - Website traffic tracking. \n\n- Google Analytics - Website traffic tracking. \n\n- Hotjar - Website traffic tracking. \n\n- Facebook - Advertising pixel for prospecting and retargeting.\n\n- Google Ads - Advertising pixel for prospecting and retargeting.\n\n- LinkedIn - Advertising pixel for prospecting and retargeting.\n\n- Quora - Advertising pixel for prospecting and retargeting.\n\n- Google Tag Manager - Tracking tag management.\n\n- Google Optimize - Website optimization and analytics."
          }
          {
            "\n\nWe may use website analytics tools and services, such as Google Analytics, to help us analyze elements of the Service and how they are used. We may also use third-party tools and services to help us deliver advertising tailored to our users’ interests. We may share data about user activities with advertising networks administered by third parties, who may use some of the same technologies described above to gather information about you. Targeted advertising may take place both within the Service and on unaffiliated websites that participate in the same advertising networks. We may use Facebook, Google Ad, LinkedIn, Quora, and Triblio for these purposes."
          }
        </Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        {/* Title */}
        <Text
          style={{
            ...globalStyles.headingLarge,
          }}
        >
          {"Do I have to accept them?"}
        </Text>
        {/* Subtitle */}
        <Text
          style={{
            ...globalStyles.bodyLargeRegular,
            marginTop: 35,
          }}
        >
          {
            "You do not have to accept cookies to use LearnBase. If you reject cookies, certain features or resources of the LearnBase’ websites may not work properly or at all and you may have a degraded experience."
          }
          {
            "\n\nTo learn more about cookies; how to control, disable or delete them, please visit http://www.aboutcookies.org. Some third party advertising networks, like Google, allow you to opt out of or customize preferences associated with your internet browsing. You can read more about how Google Analytics handles personal information here, and you learn how to opt out of Google Analytics here."
          }
          {
            "\n\nYou can take steps to limit or block advertising that is targeted at you based on your interests and online activities. You can learn how to control or block advertisements enabled by Google Ads here. You can control how Facebook selects advertisements by adjusting settings in the “Ads” section of your Facebook account “Settings” page. In addition, both Facebook and Google participate in the “AdChoices” program of the Digital Advertising Alliance. You can learn more about advertising networks and how to opt out of targeted advertising here."
          }
          {
            "\nAll cookies, on our website and everywhere else on the web, fall into one of four categories:\n\n"
          }
          {
            "- Strictly Necessary \n- Performance\n- Functionality\n- Targeting/Advertising\n\n"
          }
          {
            "Please note that opt-out mechanisms described above generally rely on cookies to retain your opt-out preferences. So if you reject or erase all cookies, your opt-out choices may not function properly."
          }
        </Text>
      </View>
    </View>
  );
};

export default Cookie;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEF1F5",
    paddingVertical: 70,
  },
  featureContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    marginTop: 50,
    width: "100%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  heroMobileContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  leftContainer: {
    //maxWidth: 600,
    marginBottom: 20,
  },
  rightContainer: {
    justifyContent: "flex-start",
  },
  image: {
    height: 500,
    width: 500,
    resizeMode: "contain",
  },
});
