import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const styles = StyleSheet.create({
  container: { marginBottom: 20, marginRight: 20 },
  cardContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: 150,
    width: "100%",
    padding: 16,
    marginVertical: 8,
    backgroundColor: COLORS.WHITE,
    borderRadius: 5,
    borderColor: COLORS.BORDER,
    borderWidth: 1,
    shadowColor: COLORS.BLACK,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  contentContainer: {
    flexDirection: "row",
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
  },
  leftContainer: {
    flexDirection: "row",
  },
  leftContent: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  rightContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    position: "absolute",
    right: 0,
  },
  rightContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
  },
  infoContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    fontWeight: "bold",
    fontSize: 16,
    color: COLORS.TEXT_DARK,
    textAlign: "center",
  },
  value: {
    color: COLORS.TEXT_DARK,
    fontSize: 14,
    maxWidth: 200,
    textAlign: "center",
  },
  chart: {
    height: 15,
    width: "95%",
    alignSelf: "center",
    backgroundColor: "#A1BAD3",
    borderRadius: 8,
  },
  progress: {
    height: 15,
    backgroundColor: "#151B21",
    borderRadius: 5,
  },
  chartContainer: {
    marginBottom: 24,
    justifyContent: "space-between",
    width: "66%",
    alignSelf: "center",
  },
  textContainer: {
    flexDirection: "row",
    width: "100%",
  },
  stepCount: {
    color: "#8D8BA5",
    fontSize: 14,
    fontWeight: "bold",
    marginRight: 10,
  },

  addButton: {
    height: 24,
    minWidth: 124,
    borderRadius: 6,
    backgroundColor: "#DADCEE",
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  badge: {
    height: 24,
    minWidth: 64,
    borderRadius: 6,
    backgroundColor: "#DADCEE",
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  titleText: {
    color: "#050038",
    fontSize: 18,
    fontWeight: "bold",
    marginRight: 10,
    width: "100%",
  },
  editButton: {
    height: 28,
    width: 28,
    borderRadius: 6,
    backgroundColor: "#DADCEE",
    padding: 10,
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    width: 250,
  },
});
