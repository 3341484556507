import { View, Modal } from "react-native";
import React from "react";
import { styles } from "./styles";
import { COLORS } from "../../constants/colors";
import { ModalBody, ModalFooter, ModalHeader } from "../Modal";

const AddTutorialModal = ({ visible, setVisible }) => {
  const handleSave = (section) => {
    null;
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <Modal visible={visible} animationType="none" transparent={true}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <ModalHeader text={"Add Tutorial"} onClose={onClose} />
          <ModalBody />
          <ModalFooter onPress={handleSave} onClose={onClose} />
        </View>
      </View>
    </Modal>
  );
};

export default AddTutorialModal;

export const EditTutorialModal = ({ visible, setVisible, data }) => {
  const handleSave = (section) => {
    null;
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <Modal visible={visible} animationType="none" transparent={true}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <ModalHeader text={"Edit Tutorial"} onClose={onClose} />
          <ModalBody data={data} />
          <ModalFooter onPress={handleSave} onClose={onClose} />
        </View>
      </View>
    </Modal>
  );
};
